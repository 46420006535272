import { TEventName } from './index'

export type TPixelEvent =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent'
  | 'registerSubmit'
  | 'firstOpen'

const agentEventNameMap: Record<string, string> = {
  registerSuccess: 'CompleteRegistration',
  depositSubmit: 'InitiateCheckout',
  firstDepositSubmit: 'InitiateCheckout',
  registerSubmit: 'registerSubmit',
  firstOpen: 'firstOpen'
}

function recordEvent(originEventName: TEventName, originEventValue?: any) {
  const { vipAgents = [] } = window
  const hasFacebook = vipAgents.some((v) => v.platform === 'Facebook')
  if (!hasFacebook) return

  const eventName = agentEventNameMap[originEventName]
  if (!eventName) return

  try {
    let eventValue
    if (eventName === 'InitiateCheckout') {
      eventValue = {
        value: originEventValue?.amount || 0,
        currency: 'VND'
      }
    }
    window.fbq?.('track', eventName as TPixelEvent, eventValue)
  } catch (e) {}
}

export default {
  name: 'metaPixel',
  recordEvent
}
