export enum FbSportsAPI {
  GetUserBalance = '/v1/user/base',
  Statistical = '/v1/match/statistical',
  StaticList = '/language/staticList',
  MatchList = '/v1/match/getList',
  BatchBetMatchMarketOfJumpLine = '/v1/order/batchBetMatchMarketOfJumpLine',
  SinglePass = '/v1/order/bet/singlePass',
  GetStakeOrderStatus = '/v1/order/getStakeOrderStatus'
}

// 登录状态失败
export enum LoginErrorCodes {
  TokenInvalid = 14006, // 签名无效
  HeaderInvalid = 14008, // 请求header无效
  NotLogin = 14009, // 账号未登录
  LOGIN_OUT = 14010 // 账号已登出
}

// FB国际化语言类型
export enum LANGUAGE_TYPE {
  ENG = 'ENG',
  CMN = 'CMN',
  VN = 'VIE'
}

export interface ILangStatic {
  [key: string]: { [key: number]: { [key in LANGUAGE_TYPE]: string | undefined } | undefined } | undefined
}

// 赛事分组类型 => 滚球
export const LIVE_TYPE = 1

// 赔率类型 香港盘
export const ODDS_TYPE = 2
// 币种类型 越南盾
export const CURRENCY_ID = 10

// 运动种类id枚举
export enum SportIds {
  Soccer = 1, // 足球
  IceHockey = 2, // 冰球
  Basketball = 3, // 篮球
  Rugby = 4, // 橄榄球
  Tennis = 5, // 网球
  AmericanFootball = 6, // 美国足球
  Baseball = 7, // 棒球
  Handball = 8, // 手球
  Floorball = 10, // 地板球
  Golf = 12, // 高尔夫球
  Volleyball = 13, // 排球
  Cricket = 14, // 板球
  TableTennis = 15, // 乒乓球
  Snooker = 16, // 斯诺克台球
  Futsal = 17, // 五人制足球
  MixedMartialArts = 18, // 综合格斗
  Boxing = 19, // 拳击
  Darts = 20, // 飞镖
  Bowls = 21, // 草地滚球
  WaterPolo = 24, // 水球
  Cycling = 25, // 自行车
  Badminton = 47, // 羽毛球
  BeachVolleyball = 51, // 沙滩排球
  Formula1 = 92, // F1赛车
  Specials = 93, // 特殊投注
  StockCarRacing = 94, // 赛车
  MotorcycleRacing = 95, // 摩托车赛 （支持玩法：冠军）
  Olympic = 100, // 奥林匹克
  AsianGame = 101, // 亚运会 （支持玩法：冠军）
  Dota2 = 164, // 刀塔2
  LOL = 165, // 英雄联盟
  EFootball = 177, // 电子足球
  EBasketball = 178, // 电子篮球
  CSGO = 179, // 反恐精英
  KOG = 180, // 王者荣耀
  VirtualSoccer = 1001, // 虚拟足球 （支持玩法：普通）
  VirtualHorse = 1020, // 虚拟赛马 （支持玩法：普通）
  VirtualGreyhounds = 1021, // 虚拟赛狗 （支持玩法：普通）
  VirtualSpeedway = 1022, // 虚拟沙地摩托车 （支持玩法：普通）
  VirtualMotorbike = 1023 // 虚拟摩托车 （支持玩法：普通）
}

// 订单状态
export enum OrderStatus {
  Created = 0, // 未确认
  Confirming = 1, // 确认中
  Rejected = 2, // 已拒单
  Canceled = 3, // 已取消
  Confirmed = 4, // 已接单
  Settled = 5 // 已结算
}

export interface IBetProps {
  matchId: number
  matchName: string
  matchTime: string
  leagueName: string
  marketId: number
  marketName: string
  optionName: string
  optionType: number
  optionOdds: number // 欧盘赔率，目前我们只提供欧洲盘赔率，投注是请提交该字段赔率值作为选项赔率，赔率小于0代表锁盘
  optionBOdds: number // 赔率，根据请求参数返回对应类型赔率
  className?: string
  minAmount?: number
  maxAmount?: number
}

export interface IUserBalanceRes {
  currencyId: number // 币种ID , see enum: currency
  uid: string // FB系统用户ID
  bl: string // 余额
  // 币种余额集合
  cbs: {
    bl: string // 余额
    cid: number // 币种ID , see enum: currency
  }[]
}

export interface IStatisticalRes {
  tc: number // 赛事个数
  sl: {
    ty: number // 分类类型，如 滚球、今日、早盘等，返回对应枚举code , see enum: match_play_type
    des: string // 分类描述
    tc: number // 赛事总数
    ssl: {
      sid: number // 运动ID , see enum: sports
      c: number // 统计赛事个数
    }[] //每个类型下每个运动里的赛事统计信息
  }[] // 所有赛事对应的不同类型的场次集合
  ht: number // 热门总数，包括竞彩赛事和热门联赛赛事
  hls: {
    id: number // ID
    mt: number // 赛事总数
    na: string // 联赛名称
    lurl: string // logo的url地址
    sid: number // 运动种类ID
  }[] // 热门联赛下的赛事个数统计
}

export interface IGetMatchListReq {
  // 盘口类型集合
  marketTypes?: number[] // 个数必须在0和4之间
  // 运动ID , see enum: sports
  sportId?: number
  // 运动id集合 , see enum: sports
  sportIds?: number[]
  // 联赛ID，matchIds、leagueId、type三者必传其一
  leagueId?: number
  // 赛事分组类型，例如：1、滚球，3、今日，传数字类型编号，matchIds、leagueId、type三者必传其一，其他情况type必传 , see enum: match_play_type
  type?: number
  // 联赛id集合，可批量获取多个联赛的赛事及赔率信息
  leagueIds?: number[]
  // 查询开始时间戳，13位时间戳，与查询结束时间戳一起组成闭区间，仅当查询早盘或串关时支持此参数，按日查询时用西四区时区划分时间段
  beginTime?: number
  // 查询结束时间戳，参考beginTime
  endTime?: number
  // 赛事Id集合，批量查询赛事ID对应的赛事列表和赔率，matchIds、leagueId、type三者必传其一
  matchIds?: number[]
  // 当前页码，从1开始计数
  current?: number
  // 每页大小, 默认50，一页最多50
  size?: number // 最小不能小于-2
  // 赛事列表排序方式：0 按开赛时间排序，1 按联赛排序， 传：0或1 , see enum: get_match_list_order_by_enum
  orderBy?: number
  // 是否为PC页面请求，App接入不传该参数，PC页面传true
  isPC?: boolean
  // 赔率类型 , see enum: odds_format_type_enum
  oddsType?: number
}

export interface IGetMatchListRes {
  current: number // 当前页码
  size: number // 每页显示行数
  total: number // 总条数
  pageTotal: number // 总页数
  records: IMatchItem[]
}

export interface IMatchItemMarketOption {
  na: string // 选项全称，投注框一般用全称展示
  nm: string // 选项简称(全名or简名，订单相关为全名，否则为简名)， 赔率列表一般都用简称展示
  ty: number // 选项类型，主、客、大、小等，投注时需要提交该字段作为选中的选项参数
  od: number // 欧盘赔率，目前我们只提供欧洲盘赔率，投注是请提交该字段赔率值作为选项赔率，赔率小于0代表锁盘
  bod: number // 赔率，根据请求参数返回对应类型赔率
  odt: number // 赔率类型
  otcm: number // 选项结算结果，仅虚拟体育展示
  li: string // 带线玩法的线，老版本兼容字段，可忽略。如大小球 大 2.5 中的 2.5
  [property: string]: any
}

export interface IMatchItem {
  // 比分列表，提供各个赛事阶段的比分
  nsg: {
    pe: number // 赛事阶段
    tyg: number // 比分类型，如 比分、角球、红黄牌等类型
    sc: number[] // 比分，数组形式，第一个数是主队分，第二个数是客对分
  }[]
  // 赔率列表
  mg: {
    mty: number // 玩法类型，如 亚盘、大小球等
    pe: number // 玩法阶段，如足球上半场、全场等，和玩法类型组合成一个玩法
    mks: {
      op: IMatchItemMarketOption[] // 玩法选项
      id: number // 玩法ID
      ss: number // 玩法销售状态，0暂停，1开售，-1未开售（未开售状态一般是不展示的）
      au: number // 是否支持串关，0 不可串关，1 可串关
      mbl: number // 是否为最优线，带线玩法可用该字段进行排序，从小到大
      li: string // line值，带线玩法的线，例如大小球2.5线，部分玩法展示可用该字段进行分组展示
    }[] // 玩法赔率集合，带线玩法，数组里是多个，或者一个玩法，不带线玩法，数组就是一条数据
    tps: string[] // 玩法展示分类数组， 如：热门、角球、波胆等，返回英文字母简称
    nm: string // 玩法名称
  }[]
  tms: number // 单个赛事玩法总数
  tps: string[] // 盘口组标签集合
  lg: {
    //联赛信息
    mt: number // 该联赛开售的赛事统计
    na: string // 联赛名称
    id: number // 联赛ID
    or: number // 联赛等级，可用于联赛排序，值越小，联赛等级越高
    lurl: string // 联赛图标地址
    sid: number // 运动种类id
    rid: number // 区域id
    rnm: string // 区域名称
    rlg: string // 区域logo
    hot: boolean // 是否热门
    slid: number // 联赛分组
  }
  ts: {
    // 球队信息，第一个是主队，第二个是客队
    na: string // 球队名称
    id: number // 球队id
    lurl: string // 球队图标地址
  }[]
  mc: {
    // 比赛时钟信息，滚球走表信息
    s: number // 走表时间，以秒为单位，如250秒，客户端用秒去转换成时分秒时间
    pe: number // 赛事阶段，如 足球上半场，篮球第一节等
    r: boolean // 是否走表，true为走表，false为停表
    tp: number // 走表类型
  }
  id: number // 赛事 ID
  bt: number // 赛事开赛时间
  ms: number // 赛事进行状态，返回赛事进行状态code，具体请对照枚举
  fid: number // 赛制的场次、局数、节数
  fmt: number // 赛制
  ss: number // 销售状态，1 开售，2 暂停，其他状态都不展示
  ne: number // 中立场标记，0 非中立场 ，1 中立场
  as: string[] // 动画直接地址集合
  sid: number // 运动ID
  ssi: number // 主/客发球，1主队发球，2客队发球
  mp: string // 赛事辅助标记，如附加赛、季前赛等，具体请看枚举
  smt: number // 滚球赛事当前阶段标识：常规时间，加时赛，点球大战等
  ty: number // 赛事类型 1 冠军投注赛事，2 正常赛事
  ye: string // 冠军赛事联赛赛季，返回赛季字符串，如：2019年
  nm: string // 冠军赛赛事名称，用于展示名称
  sb: {
    // 比分板信息
    ihs: number // 冰球，主队当前被罚下场的人数
    ias: number // 冰球，客队当前被罚下场的人数
    rp: string // 橄榄球，进攻方
    rd: number // 橄榄球，第几次进攻
    ry: number // 橄榄球，本次进攻剩余的码数
    sv: string // 斯诺克，谁在打球
    srr: number // 斯诺克，剩余红球的数量
    bs: number // 棒球，好球数量
    bb: number // 棒球，坏球数量
    bo: number // 棒球，出局人数
    bbs: string // 棒球，上垒，数字表示三个垒的位置，0表示没有人，1表示有人
    hhs: number // 手球，主队当前暂停的球员数量
    has: number // 手球，客队当前暂停的球员数量
    co: number // 板球，回合
    cd: number // 板球，球
  }
  pl: number // 是否可以开售滚球盘口
}

export interface IGetBatchBetMatchMarketReq {
  // 赛事盘口数据对象
  betMatchMarketList: {
    marketId: number // 玩法ID
    matchId: number //赛事ID
    type: number // 投注项类型 , see enum: selection_type
    oddsType?: number // 赔率类型 , see enum: odds_format_type_enum
  }[]
  isSelectSeries: boolean // 是否查询串关
  currencyId?: number // 币种id，免转钱包必传
}

export interface IGetBatchBetMatchMarketRes {
  // 玩法选项实时赔率及限额
  bms: {
    mid: number // 玩法id
    op: {
      na: string // 选项全称，投注框一般用全称展示
      nm: string // 选项简称(全名or简名，订单相关为全名，否则为简名)，赔率列表一般都用简称展示
      ty: number // 选项类型，主、客、大、小等，投注时需要提交该字段作为选中的选项参数 , see enum: selection_type
      od: number // 欧盘赔率，目前我们只提供欧洲盘赔率，投注是请提交该字段赔率值作为选项赔率，赔率小于0代表锁盘
      bod: number // 赔率，根据请求参数返回对应类型赔率
      odt: number // 赔率类型 , see enum: odds_format_type_enum
      otcm: number // 选项结算结果，仅虚拟体育展示 , see enum: outcome
      li: string // 带线玩法的线，老版本兼容字段，可忽略。如大小球 大 2.5 中的 2.5
    }
    smin: number // 单关，最小投注额限制
    smax: number // 单关，最大投注额限制
    au: number // 是否支持串关，0 不支持，1 支持 , see enum: all_up_enum
    ss: number // 玩法销售状态，0暂停，1开售，-1未开售（未开售状态一般是不展示的） , see enum: market_curt_sale_status_enum
    re: string // 足球让球当前比分， 如1-1
    omid: number // 失效玩法id，主要用于带线（球头）玩法变线后，替换原来失效的玩法id，用omid查询到对应玩法，然后替换成 mid
    ip: number // 是否为滚球 1滚球 0非滚球 , see enum: in_play_enum
  }[]
  // 串关组合赔率及限额
  sos?: {
    sn: number // 串关子单选项个数，如：投注4场比赛的3串1，此字段为3，如果是全串关（4串11×11），则为0；
    in: number // 串关子单个数，如 投注4场比赛的3串1*4，此字段为4，全串关（4串11×11），则为11
    sodd: number // 串关对应的赔率
    mi: number // 串关，最小投注额
    mx: number // 串关，最大投注额
  }[]
  mon: number // 单关，批量允许最大订单个数
  msl: number // 串关，订单最大选项个数（关数）
}

export interface ISinglePassReq {
  /**
   * 设备ID，个数必须在0和64之间
   */
  deviceId?: string

  /**
   * 单关投注数组，支持一次提交多个单关订单，批量投注，最多10个订单
   * 不能为空
   */
  singleBetList: {
    /**
     * 每单的投注金额
     * 不能为null，最小不能小于0
     */
    unitStake: number

    /**
     * 是否接受赔率变更类型code
     * 0:不接受赔率变动 1:接受最优赔率变动 2:接受任意赔率变动
     * 不能为null
     */
    oddsChange: number // 使用枚举类型odds_change_enum

    /**
     * 投注玩法选项，数组大小为1
     * 个数必须在1和1之间，不能为空
     */
    betOptionList: {
      /**
       * 玩法ID，对应赛事赔率列表中的 "data.records.mg.mks.id"
       * 不能为null
       */
      marketId: number

      /**
       * 投注选项类型code，如大小球的 大、小选项类型，对应赛事赔率列表中的 "data.records.mg.mks.op.ty"
       * 不能为null
       */
      optionType: number // 使用枚举类型selection_type

      /**
       * 下注赔率（无论前端展示哪种赔率，这里都传欧洲盘赔率），对应赛事赔率列表中的 "data.records.mg.mks.op.od"
       * 不能为null
       */
      odds: number

      /**
       * 下注时展示的赔率类型code，如港盘、欧盘，主要用于订单列表展示用户的盘口类型
       * 不能为null
       */
      oddsFormat: number // 使用枚举类型odds_format_type_enum
    }[]

    /**
     * 三方数据关联ID，可为空，下单时三方带的订单ID
     */
    relatedId?: string

    /**
     * 第三方备注
     */
    thirdRemark?: string
  }[]

  /**
   * 币种id，免转钱包必传
   */
  currencyId?: number // 使用枚举类型currency
}

export interface ISinglePassItem {
  id: string // 订单ID，返回为字符串
  st: OrderStatus // 下单后订单状态code，由于系统为异步处理订单，下单后订单处于未确认状态 , see enum: order_status
  ops: {
    mid: string // 盘口id
    od: string // 欧式赔率
    of: number // 赔率类型 , see enum: odds_format_type_enum
    bod: string // 下注赔率
    tr: string // 第三方备注
    rid: string // 下单时三方带的订单ID
  }[] // 订单选项集合
}

export interface IOrderStatusItem {
  oid: string // 预约订单ID
  st: OrderStatus // 订单状态, see enum: order_status
  rj?: number // 拒单原因码 , see enum: order_reject_type
  rjs?: string // 拒单原因
}
