import { App } from 'vue'
import { createPinia, defineStore, getActivePinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
export const setupStore = (app: App) => {
  pinia.use(piniaPluginPersistedstate)
  pinia.use(({ store }) => {
    const initialState = JSON.parse(JSON.stringify(store.$state))
    store.$reset = () => {
      store.$state = JSON.parse(JSON.stringify(initialState))
    }
  })
  app.use(pinia)
}

/**
 * 清空pinia store
 * @param [excepts] 排除某些store(不传清空所有)
 * @returns void
 */
export function resetStoresExcept(excepts?: string[]) {
  const activepinia = getActivePinia()
  if (activepinia) {
    Object.entries(activepinia.state.value).forEach(([storeName, state]) => {
      if (excepts?.includes(storeName)) {
        return
      }
      const storeDefinition = defineStore(storeName, state)
      const _store = storeDefinition(activepinia)
      _store.$reset()
    })
  }
}

export { pinia }
