<script setup lang="ts"></script>

<template>
  <div class="TabsUI">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.TabsUI {
  width: 100%;
  height: 54px;
  background-color: #282828;
  border-radius: 16px;
  margin-top: auto;
  display: flex;
  box-sizing: border-box;
  padding: 12px;
  column-gap: 12px;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
}
</style>
