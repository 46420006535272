import { defineStore } from '@/store/defineStore'
import { getAnnounceInfos, getHomeNotice as getHomeNoticeApi, hasRead } from '@/service/message'
import { ref } from 'vue'
import { IGGetAnnouncementRecord, IHomeNotice } from '@/service/message/type'
import { useI18n } from 'vue-i18n'

const useNoticeStore = defineStore(
  'notice',
  () => {
    const showNodata = ref(false)
    const refreshing = ref(false)
    const orderList = ref<IGGetAnnouncementRecord[]>([])
    const listQuery = ref({ page: 1, limit: 10 })
    const loading = ref(false)
    const finished = ref(false)
    const tabType = ref<number>(-1)
    const hideHomeNotice = ref(false)
    const noticeIds = ref<number[]>([])
    const { t } = useI18n()

    const homeNotice = ref<IHomeNotice[]>([])

    const getOrderType = (type: number | undefined) => {
      switch (type) {
        case 1:
          return t('profile.funding.recharge')
        case 2:
          return t('profile.funding.withdrawl')
        case 3:
          return t('profile.funding.addCoin')
        case 4:
          return t('profile.funding.deleteCoin')
        case 5:
          return t('profile.funding.dividend')
        case 6:
          return t('profile.funding.rebate')
        case 7:
          return t('profile.funding.rechargeByOther')
        case 8:
          return t('profile.funding.redPacket') // 红包
        case 9:
          return t('profile.funding.sendGifts') // 打赏
        case 99:
          return t('profile.funding.unknow')
        default:
          return ''
      }
    }

    const resetData = () => {
      listQuery.value.page = 1
      orderList.value = []
      refreshing.value = false
      loading.value = false
    }

    /**
     * 仅给首页浮动面板使用，只需要七条数据，不翻页，类型固定
     */
    const getHomeMessages = async () => {
      let { data } = await getAnnounceInfos({ messageType: 0, size: 7, current: 1 })
      const { records } = data
      return records
    }

    const getFundingData = async (messageType: number) => {
      tabType.value = messageType
      const params: any = {
        messageType: messageType,
        size: listQuery.value.limit,
        current: listQuery.value.page
      }
      try {
        const { data } = await getAnnounceInfos(params)
        const res = data
        if (res.records.length === 0 && listQuery.value.page === 1) {
          showNodata.value = true
        } else {
          showNodata.value = false
        }

        if (listQuery.value.page === 1) {
          orderList.value = res.records
        } else {
          res.records.forEach((item: any) => {
            orderList.value.push(item)
          })
          loading.value = false
        }
        if (orderList.value.length >= res.total) {
          finished.value = true
          listQuery.value.page = 1
        } else {
          listQuery.value.page++
          loading.value = false
          finished.value = false
        }
      } catch (e) {
        return Promise.reject(e)
      }
    }

    // 上拉加载
    const onLoad = () => {
      getFundingData(tabType.value)
    }

    // 下拉刷新
    const onRefresh = () => {
      orderList.value = []
      listQuery.value.page = 1
      getFundingData(tabType.value)
      refreshing.value = false
      loading.value = false
    }

    const getHomeNotice = async () => {
      try {
        const { data } = await getHomeNoticeApi()
        const filterArr = data.filter((v) => v.title)
        if (!filterArr.length) {
          hideHomeNotice.value = true
        }
        homeNotice.value = filterArr
      } catch (e) {}
    }

    // 获取会员消息强弹窗
    const getUserNoticePopup = async () => {
      try {
        const { data } = await getAnnounceInfos({ messageType: 0, size: 10, current: 1 })
        if (data && data.records && data.records.length) {
          const noticeData = data.records
            .filter((item: IGGetAnnouncementRecord) => item.isPopup && item.status === 1)
            .map((item: IGGetAnnouncementRecord) => {
              return {
                createTime: item.createTime,
                title: item.title,
                message: item.message,
                id: item.id
              }
            })
          applyNoticePopup(noticeData, 0)
        }
      } catch (e) {}
    }

    type NoticePopupProps = {
      createTime?: number
      title?: string
      message?: string
      id: number
    }

    // 首页公告和会员消息强弹窗
    const showNoticePopup = ref(false)
    const noticePopupActiveIndex = ref(0)
    const noticePopupList = ref<NoticePopupProps[]>([])

    const applyNoticePopup = (data: NoticePopupProps[], index: number) => {
      noticePopupList.value = data
      noticePopupActiveIndex.value = index
      noticeIds.value = []
      if (data.length) {
        showNoticePopup.value = true
      }
    }

    // 是否已读
    const hasReads = async (id: number, messageType: number) => {
      await hasRead({ id, messageType })
    }

    return {
      showNodata,
      refreshing,
      onRefresh,
      orderList,
      listQuery,
      loading,
      finished,
      onLoad,
      getFundingData,
      resetData,
      getHomeMessages,
      getHomeNotice,
      hideHomeNotice,
      homeNotice,
      getOrderType,
      showNoticePopup,
      noticePopupList,
      noticePopupActiveIndex,
      getUserNoticePopup,
      applyNoticePopup,
      hasReads,
      noticeIds
    }
  },
  { persist: false }
)
export default useNoticeStore
