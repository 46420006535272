export type TGAEvent =
  | 'getRegistrationVerifyCode'
  | 'registerSubmit'
  | 'registerSuccess'
  | 'firstDepositSubmit'
  | 'depositSubmit'
  | 'downloadApp'
  | 'depositOpen'
  | 'firstDepositOpen'
  | 'firstDeposit'
  | 'betBonus'
  | 'slotBetBonus'
  | 'invite'
  | 'rebate'
  | 'midAutumn'
  | 'sportsBetting'

const agentEventNameMap: Record<string, string> = {
  getRegistrationVerifyCode: 'getRegistrationVerifyCode',
  registerSubmit: 'registerSubmit',
  registerSuccess: 'registerSuccess',
  firstDepositSubmit: 'firstDepositSubmit',
  depositSubmit: 'depositSubmit',
  downloadApp: 'downloadApp',
  depositOpen: 'depositOpen',
  firstDepositOpen: 'firstDepositOpen',
  firstDeposit: 'firstDeposit',
  betBonus: 'betBonus',
  slotBetBonus: 'slotBetBonus',
  invite: 'invite',
  rebate: 'rebate',
  midAutumn: 'midAutumn',
  sportsBetting: 'sportsBetting'
}

function recordEvent(originEventName: TGAEvent, originEventValue?: any) {
  const eventName = agentEventNameMap[originEventName]
  if (!eventName) return
  try {
    let eventValue
    if (eventName === 'Purchase') {
      eventValue = {
        value: originEventValue?.amount || 0,
        currency: 'VND'
      }
    }
    window.gtag?.('event', eventName as TGAEvent, eventValue || originEventValue)
  } catch (e) {
    console.error(e)
  }
}

export default {
  name: 'googleAnalytics',
  recordEvent
}
