import { createRouter, createWebHistory } from 'vue-router'
import useAppStore from '@/store/app'
import useUserStore from '@/store/user'
import { i18n } from '@/locale'
import { deviceInfo, setStatusBarColor } from '@/utils/native'

const {
  global: { t }
} = i18n

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: '404'
    }
  },
  {
    name: 'maintenance',
    path: '/maintenance',
    component: () => import('@/views/maintenance/index.vue'),
    meta: {
      title: t('errorPage.maintenance')
    }
  },
  {
    name: 'home',
    path: '/',
    redirect: '/game',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: t('global.home')
    },
    children: [
      {
        name: 'game',
        path: '/game',
        component: () => import('@/views/home/game.vue'),
        meta: {
          title: t('global.home')
        }
      }
    ]
  },
  {
    name: 'join',
    path: '/join/:code',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: t('global.home')
    }
  },
  {
    name: 'joinVip',
    path: '/:code(vip\\d+)',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: t('global.home')
    }
  },
  {
    name: 'help',
    path: '/help',
    redirect: '/help/menu',
    children: [
      {
        name: 'menu',
        path: 'menu',
        component: () => import('@/views/help/index.vue'),
        meta: {
          auth: false
        }
      },
      {
        name: 'recharge',
        path: 'recharge',
        component: () => import('@/views/help/recharge/index.vue'),
        meta: {
          auth: false
        }
      },
      {
        name: 'revocation',
        path: 'revocation',
        component: () => import('@/views/help/withdraw/index.vue'),
        meta: {
          auth: false
        }
      },
      {
        name: 'allrules',
        path: 'allrules',
        component: () => import('@/views/help/betRules/index.vue'),
        meta: {
          auth: false
        }
      },
      {
        name: 'HelpDetail',
        path: 'betdetail',
        component: () => import('@/views/help/betRules/detail.vue'),
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    name: 'settings',
    path: '/settings',
    redirect: '/settings/index',
    meta: {
      title: t('settings.home'),
      auth: true
    },
    children: [
      {
        name: 'settingsIndex',
        path: 'index',
        component: () => import('@/views/settings/index.vue'),
        meta: {
          title: t('settings.home')
        }
      },
      {
        name: 'messages',
        path: 'messages',
        component: () => import('@/views/settings/message/index.vue'),
        meta: {
          title: t('settings.home')
        }
      },
      {
        name: 'dailyCheckin',
        path: 'dailyCheckin',
        component: () => import('@/views/settings/dailyCheckin/index.vue'),
        meta: {
          title: t('settings.home')
        }
      },
      {
        name: 'selfInfo',
        path: 'self',
        component: () => import('@/views/settings/selfInfo/index.vue'),
        meta: {
          title: t('settings.home')
        }
      },
      {
        name: 'vip',
        path: 'vip',
        component: () => import('@/views/settings/vip/index.vue'),
        meta: {
          title: t('settings.home')
        }
      },
      {
        name: 'vipDetail',
        path: 'vipDetail',
        component: () => import('@/views/settings/vip/vipDetail.vue'),
        meta: {
          title: t('settings.home')
        }
      }
    ]
  },
  {
    name: 'service',
    path: '/service',
    component: () => import('@/views/chat/index.vue'),
    meta: {}
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      tabs: [
        {
          name: t('login.tab.guest'),
          path: '/register'
        },
        {
          name: t('login.tab.member'),
          path: '/login'
        }
      ]
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/register/index.vue'),
    meta: {
      tabs: [
        {
          name: t('login.tab.guest'),
          path: '/register'
        },
        {
          name: t('login.tab.member'),
          path: '/login'
        }
      ]
    }
  },
  {
    name: 'wallet',
    path: '/wallet',
    component: () => import('@/views/wallet/index.vue'),
    redirect: '/wallet/funding',
    meta: {
      title: t('global.deposit'),
      auth: true,
      tabs: [
        {
          name: t('global.deposit'),
          path: '/wallet/deposit/form',
          parent: '/wallet/deposit'
        },
        {
          name: t('profile.menu.fundDetails'),
          path: '/wallet/funding'
        },
        {
          name: t('global.withdraw'),
          path: '/wallet/withdraw/form',
          parent: '/wallet/withdraw'
        }
      ],
      headerTitle: t('wallet.name')
    },
    children: [
      {
        name: 'deposit',
        path: 'deposit',
        component: () => import('@/views/wallet/deposit/index.vue'),
        redirect: '/wallet/deposit/form',
        meta: {
          title: t('global.deposit')
        },
        children: [
          {
            name: 'depositForm',
            path: '/wallet/deposit/form',
            component: () => import('@/views/wallet/deposit/form.vue'),
            meta: {
              title: t('global.deposit')
            }
          },
          {
            name: 'depositInformation',
            path: '/wallet/deposit/information',
            component: () => import('@/views/wallet/deposit/information.vue'),
            meta: {
              title: t('global.deposit'),
              parent: '/wallet/deposit'
            }
          }
        ]
      },
      {
        name: 'withdraw',
        path: 'withdraw',
        component: () => import('@/views/wallet/withdraw/index.vue'),
        redirect: '/wallet/withdraw/form',
        meta: {
          title: t('global.withdraw')
        },
        children: [
          {
            name: 'withdrawForm',
            path: '/wallet/withdraw/form',
            component: () => import('@/views/wallet/withdraw/form.vue'),
            meta: {
              title: t('global.withdraw')
            }
          },
          {
            name: 'withdrawInformation',
            path: '/wallet/withdraw/information',
            component: () => import('@/views/wallet/withdraw/information.vue'),
            meta: {
              title: t('global.withdraw'),
              parent: '/wallet/withdraw'
            }
          }
        ]
      },
      {
        name: 'funding',
        path: 'funding',
        component: () => import('@/views/wallet/funding/index.vue'),
        meta: {
          title: t('profile.menu.fundDetails')
        }
      }
    ]
  },
  {
    name: 'transaction',
    path: '/transaction',
    component: () => import('@/views/transaction/index.vue')
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('@/views/order/index.vue'),
    meta: {
      title: t('global.order'),
      auth: true
    },
    children: [
      {
        name: 'orderDetail',
        path: 'detail',
        component: () => import('@/views/order/detail/index.vue'),
        meta: {
          title: t('global.order')
        }
      }
    ]
  },
  {
    name: 'sports',
    path: '/sports',
    component: () => import('@/views/sports/index.vue'),
    meta: {
      title: t('global.sports'),
      auth: true
    }
  },
  {
    name: 'sub-games',
    path: '/sub-games/:id',
    component: () => import('@/views/home/sub-games.vue'),
    meta: {
      title: t('home.game.sublist')
    }
  },
  {
    name: 'promo',
    path: '/promo',
    component: () => import('@/views/promo/index.vue'),
    children: [
      {
        name: 'promo-details',
        path: 'promo-details/:name',
        component: () => import('@/views/promo/promo-details.vue'),
        meta: {
          title: t('promotion.activity')
        }
      }
    ]
  },
  {
    name: 'authTransferPage',
    path: '/authTransferPage',
    component: () => import('@/views/authTransferPage/index.vue')
  },
  {
    name: 'downloadvip',
    path: '/download/:code(vip\\d+)',
    component: () => import('@/views/download/index.vue'),
    meta: {
      title: 'download'
    }
  },
  {
    name: 'download',
    path: '/download',
    component: () => import('@/views/download/index.vue'),
    meta: {
      title: 'download'
    }
  },
  {
    name: 'downloadLight',
    path: '/download/light',
    component: () => import('@/views/download/light.vue')
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/views/404/404Ele.vue')
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && to.path !== '/game') {
    // 离开首页，移除首页骨架屏
    document.body.classList.remove('skeleton-home')
  }
  if (to.path !== '/promo') {
    // 离开优惠页面，移除优惠骨架屏
    document.body.classList.remove('skeleton-promo')
  }

  const { title, auth } = to?.meta

  if (title) {
    document.title = title as string
  }

  const { isLogin } = useUserStore()
  const { beforeunloadPath, setBeforeunloadPath } = useAppStore()

  setBeforeunloadPath(to.path)

  if (!deviceInfo.isBrowser) {
    setStatusBarColor(to.path)
  }

  // 判断是否为原生内嵌需要获取登录信息的页面
  if (to.query.inNative && +to.query.inNative === 1 && (auth || to.path.includes('promo'))) {
    next({ path: '/authTransferPage', query: { redirect: to.path } })
  }

  if (isLogin) {
    if (from.path !== '/sports' && beforeunloadPath === '/sports') {
      // 上一次浏览器关闭的时候是体育页面
      next('/sports')
      // 跳转完成后重置
      setBeforeunloadPath('/')
    } else if (to.path.includes('/login') || to.path.includes('/register')) {
      next('/')
    } else {
      next()
    }
  } else {
    if (auth) {
      next(`/login?fromPath=${from.path}`)
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  // 移除骨架屏
  document.body.classList.remove('skeleton')
  // 动态调整 body 的高度样式
  document.body.style.height = 'auto'
  requestAnimationFrame(() => {
    setTimeout(() => {
      document.body.style.height = '100%'
    }, 0)
  })
})

export default router
