import { IBanner } from '@/service/promotion/types'
import { defineStore } from '../defineStore'
import { ref } from 'vue'
import { getBanner } from '@/service/promotion'

const useBannerStore = defineStore('banner', () => {
  const bannerList = ref<IBanner[]>([])
  const getBannerList = async () => {
    const { data } = await getBanner()
    bannerList.value = data
  }
  return {
    bannerList,
    getBannerList
  }
})

export default useBannerStore
