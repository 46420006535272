import { defineStore } from '@/store/defineStore'
import { computed, ref, watch } from 'vue'
import useUserStore from '@/store/user'
import { gameLogin, guestLogin } from '@/service/game'
import { IGameLoginRequest } from '@/service/game/types'
import { getStaticList, getUserBalance as getUserBalanceApi } from '@/service/fbSports'
import useAppStore from '@/store/app'
import { LANGUAGES } from '@/store/app/type'
import { LANGUAGE_TYPE, ILangStatic } from '@/service/fbSports/types'

const useBettingStore = defineStore('fbSports', () => {
  const userStore = useUserStore()

  // FB-APP服务接口地址
  const baseUrl = ref('')

  // 内嵌FB体育页面url
  const loginUrl = ref('')

  const token = ref('')

  const clearToken = () => {
    token.value = ''
  }

  const setLoginInfo = (loginInfo: { token: string; apiServerAddress: string; loginUrl: string }) => {
    token.value = loginInfo.token
    baseUrl.value = loginInfo.apiServerAddress
    loginUrl.value = loginInfo.loginUrl
  }

  const isLogin = computed(() => {
    return !!token.value
  })

  const login = async (params: IGameLoginRequest) => {
    try {
      let res = null
      if (userStore.isLogin) {
        res = await gameLogin(params)
        token.value = res.data.token
        loginUrl.value = res.data.loginUrl
      } else {
        res = await guestLogin(params)
      }
      baseUrl.value = res.data.apiServerAddress

      return Promise.resolve()
    } catch (e) {
      return Promise.reject()
    }
  }

  const lang = computed(() => {
    const appStore = useAppStore()
    switch (appStore.lang) {
      case LANGUAGES.CMN:
        return LANGUAGE_TYPE.CMN
      case LANGUAGES.VN:
        return LANGUAGE_TYPE.VN
      case LANGUAGES.ENG:
        return LANGUAGE_TYPE.ENG
      default:
        return LANGUAGE_TYPE.VN
    }
  })

  // 获取FB多语言静态数据
  const langStatic = ref<ILangStatic>({})
  const getLangStatic = async () => {
    // 如果已经获取过多语言静态数据，则不再同步请求
    if (Object.keys(langStatic.value).length) {
      getStaticList()
      return Promise.resolve()
    }

    try {
      const res = await getStaticList()
      langStatic.value = res.data || {}
      return Promise.resolve()
    } catch (e) {
      return Promise.reject()
    }
  }

  const userBalance = ref(0)
  const getUserBalance = async () => {
    if (!isLogin.value) return

    try {
      const res = await getUserBalanceApi()
      userBalance.value = +(res.data?.bl || 0)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject()
    }
  }

  return { baseUrl, token, isLogin, clearToken, lang, langStatic, getLangStatic, login, getUserBalance, userBalance, setLoginInfo, loginUrl }
})

export default useBettingStore
