import {
  DefineSetupStoreOptions,
  defineStore as defineStorePinia,
  StoreDefinition,
  _ExtractActionsFromSetupStore,
  _ExtractGettersFromSetupStore,
  _ExtractStateFromSetupStore
} from 'pinia'

export const defineStore = <Id extends string, SS>(
  id: Id,
  storeSetup: () => SS,
  options?: DefineSetupStoreOptions<Id, _ExtractStateFromSetupStore<SS>, _ExtractGettersFromSetupStore<SS>, _ExtractActionsFromSetupStore<SS>>
): StoreDefinition<Id, _ExtractStateFromSetupStore<SS>, _ExtractGettersFromSetupStore<SS>, _ExtractActionsFromSetupStore<SS>> =>
  defineStorePinia(id, storeSetup, {
    persist: {
      key: id,
      storage: localStorage
    },
    ...options
  })
