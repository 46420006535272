import { service } from '@/service'
import {
  IForgetSubmitRequest,
  IGetSmsCodeRequest,
  ILoginRequest,
  ILoginResponse,
  IPhoneLoginRequest,
  IRegSubmitRequest,
  IRegSubmitResponse,
  IResetPassword,
  ISetWithdrawValRequest,
  IUserBalance,
  IValidateRequest,
  IValidateSmsCodeRequest,
  IValidateUpdateBirdthdayRequest,
  IReportDomainRequest,
  IReportDomainResponse,
  IGetDomainsDomainResponse,
  userAPI,
  IupdatePhoneRequest
} from '@/service/user/types'
import { UserInfo } from '@/store/user/types'

// 注册第一步：行为验证
export function regValidate(data: IValidateRequest) {
  return service.post<string>({
    url: userAPI.RegValidate,
    data
  })
}

// 注册第二步 获取手机验证码
export function getValidateCode(accessToken: string) {
  return service.post<string>({
    url: userAPI.GetValidateCode,
    params: { accessToken }
  })
}

// 注册第三步 提交
export function regSubmit(data: IRegSubmitRequest) {
  return service.post<IRegSubmitResponse>({
    url: userAPI.RegSubmit,
    data,
    showLoading: true
  })
}

// 获取用户信息
export function fetchUserInfo() {
  return service.get<UserInfo>({
    url: userAPI.FetchUserInfo
  })
}

// 获取用户余额
export function getUserBalance() {
  return service.post<IUserBalance>({
    url: userAPI.GetUserBalance
  })
}

// 手机登录第一步 行为验证
export function loginValidate(data: IValidateRequest) {
  return service.post<string>({
    url: userAPI.LoginValidate,
    data
  })
}

// 手机登录第二步 获取手机验证码
export function getLoginValidateCode(accessToken: string) {
  return service.post<string>({
    url: userAPI.GetLoginValidateCode,
    params: { accessToken }
  })
}

export function loginSubmit(isPhoneLogin = false, data: ILoginRequest | IPhoneLoginRequest) {
  return service.post<ILoginResponse>({
    url: isPhoneLogin ? userAPI.PhoneLogin : userAPI.Login,
    data,
    showLoading: true
  })
}

// 忘记密码第一步 行为验证
export function forgetValidate(data: IValidateRequest) {
  return service.post<string>({
    url: userAPI.ForgetValidate,
    data
  })
}

// 忘记密码第二步 获取手机验证码
export function getForgetValidateCode(accessToken: string) {
  return service.post<string>({
    url: userAPI.GetForgetValidateCode,
    params: { accessToken }
  })
}

// 忘记密码第三步 完成
export function forgetSubmit(data: IForgetSubmitRequest) {
  return service.post<boolean>({
    url: userAPI.ForgetSubmit,
    data,
    showLoading: true
  })
}

// 更新生日 完成
export function updateBirthday(params: IValidateUpdateBirdthdayRequest) {
  return service.post<boolean>({
    url: userAPI.UpdateBirthday,
    params
  })
}

// 修改密码
export function resetPassword(data: IResetPassword) {
  return service.post<boolean>({
    url: userAPI.ResetPassword,
    data,
    showLoading: true
  })
}

// 通用获取验证码
export function getSmsCode(data: IGetSmsCodeRequest) {
  return service.post<string>({
    url: userAPI.GetSmsCode,
    data
  })
}

// 通用校验验证码
export function validateSmsCode(params: IValidateSmsCodeRequest) {
  return service.post({
    url: userAPI.ValidateSmsCode,
    params
  })
}

// 提现短信验证
export function setWithdrawVal(params: ISetWithdrawValRequest) {
  return service.post<Boolean>({
    url: userAPI.SetWithdrawVal,
    params
  })
}

// 检查用户状态
export function checkStatus() {
  return service.get({
    url: userAPI.CheckStatus
  })
}

// 用户登出
export function logout() {
  return service.get({
    url: userAPI.Logout
  })
}

// 上报异常域名
export function reportDomain(data: IReportDomainRequest) {
  return service.post<IReportDomainResponse>({
    url: userAPI.reportDomain,
    data
  })
}

// 获取域名列表
export function getDomains() {
  return service.get<IGetDomainsDomainResponse>({
    url: userAPI.getDomains
  })
}

// 修改手机号
export function updatePhone(data: IupdatePhoneRequest) {
  return service.post<boolean>({
    url: userAPI.UpdatePhone,
    data
  })
}