import { showToast } from 'vant'
import { i18n } from '@/locale'
import { GameIds } from '@/types'
import { floor } from 'lodash-es'
import { getAwsHost, endpoints as DOMAINS } from '@hpoker/sdk/dist/utils/domain'
import axios from 'axios'
import { getDomains } from '@/service/user'
const {
  global: { t }
} = i18n

export const getImgUrl = (url: string) => {
  return new URL(url, import.meta.url).href
}

/**
 *  金额过滤器
 * @param value   金额
 * @param options   配置项
 * @param options.symbol 是否显示符号
 * @param options.absoult 是否取绝对值
 * @returns
 */
export function amountFilter(value = 0, options: { symbol?: boolean; absoult?: boolean } = { symbol: false, absoult: false }): string {
  const { symbol, absoult } = options
  const isPositive = value > 0
  const prefix = symbol && isPositive ? '+' : ''

  // 正数向下取整，负数向上取整
  let amount = isPositive ? Math.floor(value) : Math.ceil(value)
  if (absoult) {
    amount = Math.abs(amount)
  }
  return prefix + amount.toLocaleString()
}

/* 给数字加k */
export function amountKilo(value = 0) {
  if (value > 999) {
    return (value / 1000).toLocaleString() + 'k'
  }
  return value
}

const Numbers = {
  TRILLION: 1000000000000, // 万亿
  BILLION: 1000000000, // 十亿
  MILLION: 1000000, // 百万
  THOUSAND: 1000, // 千
  DIGIT: 1
}

/**
 * 格式化超大数字，自动根据数字带单位
 * @param value The number to format.
 * @param options The formatting options.
 * @param options.symbol Whether to include a symbol in the formatted result. Default is false.
 * @param options.precision The number of decimal places to include in the formatted result. Default is 0.
 * @returns The formatted number as a string.
 */
export function fortmatNumberWithUnits(value = 0, options: { symbol?: boolean; precision?: number } = { symbol: false, precision: 0 }): string {
  const { symbol, precision } = options
  // 是否为负数
  const isNegative = value < 0
  if (isNegative) {
    value = Math.abs(value)
  }
  let result: number | string
  if (value === 0) {
    result = 0
    // } else if (value > Numbers.TRILLION) {
    //   // 万亿
    //   result = formatValue(value, Numbers.TRILLION, precision) + 'T'
  } else if (value >= Numbers.BILLION) {
    // 十亿
    result = formatValue(value, Numbers.BILLION, precision) + 'Tỷ'
  } else if (value >= Numbers.MILLION) {
    // 百万
    result = formatValue(value, Numbers.MILLION, precision) + 'Tr'
    // } else if (value > Numbers.THOUSAND) {
    //   // 千
    //   result = formatValue(value, Numbers.THOUSAND, precision) + 'k'
  } else {
    result = formatValue(value, Numbers.DIGIT, precision)
  }
  return isNegative ? `-${result}` : symbol ? `+${result}` : result.toString()
}

/**
 * 按照精度位数 + 加千分符 一起格式化返回值
 * @param value
 * @param denominator
 * @param precision
 */
function formatValue(value: number, denominator: number, precision?: number): string {
  return floor(value / denominator, precision).toLocaleString()
}

export enum FundingLogoTypes {
  Unknown = -1, // 未知
  Deposit = 0, // 充值
  Withdraw = 1, // 提现
  Transfer = 2, // 转账
  CanadianDollars = 3, // 加币
  ReduceCoins = 4, // 减币
  Refund = 5, // 退回
  AdjustmentIsCompleted = 6, // 捕鱼
  RunningWaterClearing = 7, // 金融
  ClearLightOutput = 8 // 金融
}

export enum OrderFundingType {
  All = -1,
  Unpaid = 0, // 未结算
  Paid = 2 // 已结算
}

export enum SearchTimeType {
  Bet = 1, // 按投注时间搜索
  Paid = 2 // 按结算时间搜索
}

// 串关输赢
export function getFundingLogoName(type: FundingLogoTypes | undefined) {
  switch (type) {
    case FundingLogoTypes.Unknown:
      return 'unknown'
    case FundingLogoTypes.Deposit:
      return 'deposit'
    case FundingLogoTypes.Withdraw:
      return 'withdraw'
    case FundingLogoTypes.Transfer:
      return 'transfer'
    case FundingLogoTypes.CanadianDollars:
      return 'canadian_dollars'
    case FundingLogoTypes.ReduceCoins:
      return 'reduce_coins'
    case FundingLogoTypes.Refund:
      return 'refund'
    case FundingLogoTypes.AdjustmentIsCompleted:
      return 'adjustment_is_completed'
    case FundingLogoTypes.RunningWaterClearing:
      return 'running_water_clearing'
    case FundingLogoTypes.ClearLightOutput:
      return 'clear_light_output'
    default:
      return ''
  }
}

//获取时间戳 今天：day = 0;昨天：day=1;7天：day=7; 30天：day=30
export function getTimestamp(day: number) {
  const time = new Date(new Date(new Date().toLocaleDateString()).getTime() - day * 24 * 3600 * 1000).getTime()
  return time
}

//获取时间戳 今天：day = 0;昨天：day=1;7天：day=7; 30天：day=30
export function newGetTimestamp(day: number) {
  // 获取当前日期
  const today = new Date()

  // 获取昨日的日期
  const beforeday = new Date(today)
  beforeday.setDate(today.getDate() - day)

  // 获取昨日的开始时间戳（00:00:00）
  beforeday.setHours(0, 0, 0, 0)
  const lastTimeStartTimestamp = beforeday.getTime()

  // 获取今天的开始时间戳（00:00:00）
  today.setHours(0, 0, 0, 0)
  const todayStartTimestamp = today.getTime()
  if (day === 1) {
    return [lastTimeStartTimestamp, todayStartTimestamp]
  } else if (day === 0) {
    return [todayStartTimestamp, new Date().getTime()]
  } else {
    return [lastTimeStartTimestamp, new Date().getTime()]
  }
  // const time = new Date(new Date(new Date().toLocaleDateString()).getTime() - day * 24 * 3600 * 1000).getTime()
  // return time
}

// 隐藏手机号中间5位
export function mobileFilter(value: string | number) {
  const str = String(value)
  return `${str.slice(0, 3)}*****${str.slice(8)}`
}

// 游戏名字转换
export function getGameTitle(id: number | undefined) {
  switch (id) {
    case GameIds.Sports:
      return t('global.sports')
    case GameIds.ESports:
      return t('global.gaming')
    case GameIds.Lottery:
      return t('global.lottery')
    case GameIds.Casino:
      return t('global.realPerson')
    case GameIds.Fishing:
      return t('global.fishing')
    case GameIds.Slot:
      return t('global.electronic')
    case GameIds.Chess:
      return t('global.cards')
    case GameIds.Cockfighting:
      return t('global.cockfighting')
    default:
      return ''
  }
}

// 生日格式化
export function formatDate(inputDate: string | number) {
  const str = String(inputDate)
  if (str.length !== 8) {
    // 输入的日期字符串长度不正确
    return 'Invalid Date'
  }

  const year = str.substring(0, 4)
  const month = str.substring(4, 6)
  const day = str.substring(6, 8)

  return `${year}-${month}-${day}`
}

//资金类型转换
export function getOrderType(type: number | undefined) {
  switch (type) {
    case 0:
      return t('profile.funding.recharge')
    case 1:
      return t('profile.funding.withdrawl')
    case 2:
      return t('profile.funding.transfer')
    case 3:
      return t('profile.funding.dividend')
    case 4:
      return t('profile.funding.rechargeByOther')
    case 5:
      return t('profile.funding.addCoin')
    case 6:
      return t('profile.funding.deleteCoin')
    // case 7:
    //   return t('profile.funding.statement.clear')
    // case 8:
    //   return t('profile.funding.loseUp.clear')
    // case -1:
    //   return t('profile.funding.unknow')
  }
}

// 异步打开新窗口
export async function openWindowAsync(req: () => any) {
  const openUrl = `/loading.html`
  const newWindow = window.open(openUrl)
  try {
    const res = await req()

    if (!newWindow) {
      showToast(t('global.openWindow.errorTips'))
      return Promise.reject()
    }

    if (typeof res === 'string') {
      newWindow?.location.replace(res)
    } else {
      if (res.writeHtml) {
        newWindow?.document.write(res.html)
      }
    }

    return Promise.resolve()
  } catch (e) {
    newWindow?.close()
    return Promise.reject()
  }
}

/**
/**
 * 获取aws图片地址
 * @param path 图片path 或图片url
 * @param forceUrl 是否强制使用 path 作为 url
 * @returns string 图片地址
 */
export function getAWSImg(path: string, forceUrl = false) {
  if (!path) {
    // 如果 path 为空，则返回空字符串
    return ''
  } else if (forceUrl) {
    // 如果强制使用 url，则直接返回 path
    return path
  } else if (/^http(s)?:\/\//.test(path)) {
    // 如果是 http:// 或者 https:// 开头的地址，则拼接 path 到域名
    const url = new URL(path)
    return `${getAwsHost()}${url.pathname}`
  }
  return `${getAwsHost()}${path}`
}

// 接受一个电话号码作为参数，判断是否为9位且第一位不是0，如果是，则在前面添加0
export function formatPhoneNumber(phoneNumber: string) {
  // 判断是否为9位且第一位不是0
  const isNineDigitNonZero = /^\d{9}$/.test(phoneNumber) && phoneNumber[0] !== '0'

  // 如果满足条件，则在前面添加0
  if (isNineDigitNonZero) {
    phoneNumber = '0' + phoneNumber
  }

  return phoneNumber
}

/* 处理金额的tr单位 */
export function amountFormatTr(value = 0) {
  return fortmatNumberWithUnits(value, { precision: 3 })
}

/* sleep函数 */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Domains filter
 * @param url
 * @param [defaultSubdomains]
 * @returns
 */
export function domainFilter(url: string, defaultSubdomains = 's') {
  if (!url) {
    return ''
  }
  const newMainDomain = new URL(getAwsHost()).hostname.replace('s.', '')
  try {
    // 尝试创建一个 URL 对象
    const urlObj = new URL(url)
    // 提取子域名部分
    const subdomains = urlObj.hostname.split('.').slice(0, -2).join('.')
    // 构建新的主机名，保留子域名
    const newHostname = subdomains ? `${subdomains}.${newMainDomain}` : newMainDomain
    // 设置新的主机名
    urlObj.hostname = newHostname
    // 返回修改后的 URL 字符串
    return urlObj.href
  } catch (e) {
    // 如果 URL 无效（没有主机部分），则使用默认的协议 https:
    const defaultProtocol = 'https:'
    // 确保路径以斜杠开头
    const path = url.startsWith('/') ? url : '/' + url
    // 构建完整的 URL
    return `${defaultProtocol}//${defaultSubdomains}.${newMainDomain}${path}`
  }
}

/**
 * Checks domains for availability.
 * @param domains
 * @returns
 */
export async function checkDomains() {
  const defaultProdDomains = import.meta.env.VITE_APP_ENV === 'production' ? DOMAINS.map((item: string) => `https://client-api.${item}`) : []
  const domainRes = await getDomains()
  const domains = domainRes.data.filter((item) => item.type === 6 && item.url).map((item) => item.url as string)
  // defaultProdDomains和 domains合并去重
  const allDomains = Array.from(new Set([...defaultProdDomains, ...domains]))
  const requests = allDomains.map((domain) => {
    return axios
      .head(domain + '/user/check/status')
      .then((response) => {
        // 如果状态码不是200，返回域名和状态码
        if (response.status !== 200) {
          return { domain, status: response.status }
        }
        return null // 请求成功且状态码为200，返回null
      })
      .catch((error) => {
        // 请求失败，返回域名和错误状态码或网络错误
        return { domain, status: error.response ? error.response.status : 'Network Error' }
      })
  })

  // 使用Promise.all同时发起所有请求
  const results = await Promise.all(requests)

  // 过滤掉成功的请求（即那些返回null的请求），只保留有错误的请求
  return results.filter((result) => result !== null)
}
