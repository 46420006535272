import { defineStore } from '../defineStore'
import { ChatWootUserIdentifier } from '@/utils/constants'
import useUserStore from '../user'
import { useChatWoot } from '@productdevbook/chatwoot/vue'
import { useRouter } from 'vue-router'

const useCustomerStore = defineStore(
  'customer',
  () => {
    const { reset, setUser, toggle, setCustomAttributes, toggleBubbleVisibility, popoutChatWindow } = useChatWoot()
    const router = useRouter()

    const goToCustomerService = async () => {
      const userStore = useUserStore()
      if (userStore.isLogin && userStore.userInfo?.id) {
        setUser(`${userStore.userInfo?.id}`, {
          name: userStore.userInfo?.userName ?? '',
          phone_number: userStore.userInfo?.telephone ?? ''
        })
        setCustomAttributes({
          userId: `${userStore.userInfo?.id ?? ''}`
        })
      }
      // toggleBubbleVisibility('hide')
      // popoutChatWindow()
      // reset()
      toggle('open')

      // router.push('/service')
    }

    const hideBubble = () => {
      toggleBubbleVisibility('hide')
    }

    return {
      hideBubble,
      reset,
      goToCustomerService
    }
  },
  {
    persist: false
  }
)

export default useCustomerStore
