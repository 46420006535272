export enum GameIds {
  Sports = 1, // 体育
  Casino = 2, // 真人
  Chess = 3, // 棋牌
  Slot = 4, // 电子
  Lottery = 5, // 彩票
  ESports = 6, // 电竞
  Fishing = 7, // 捕鱼
  Cockfighting = 8 // 斗鸡
}
