export enum CommonApi {
  GetDeviceId = '/client/get/deviceId',
  GetPlatformMaintain = '/client/game-maintain/platform',
  GetReportDataConfig = '/client/list/pointConfigs',
  DomainCheck = '/client/promoteLink/getLinkCodeByDomain',
  RecordAgentCode = '/agent/ipAgentCode/createIpAgentCode',
  GetAgentCode = '/agent/ipAgentCode/selectIpAgentCodeByIp'
}

export enum DomainCheckStatus {
  Unknow = 0,
  Normal = 1,
  Exclusive = 2
}

export interface ICommonApiRequest {
  /**
   * 网易易顿token
   */
  token: string
}

export interface ICommonApiResponse {
  /**
   * 设备ID
   */
  deviceId: string
  taskId: string
}

export interface IPlatformMaintainResponse {
  /**
   * 设备，0:PC,1:H5,2:ANDROID,3:IOS
   */
  deviceType?: number[]
  /**
   * 禁用，1:入口
   */
  disableService?: number
  /**
   * 结束时间
   */
  endTime?: number
  /**
   * 开始时间
   */
  startTime?: number
  /**
   * 维护状态，0:无维护 1:维护中
   */
  status: number
  [property: string]: any
}

export interface IDomainCheckRequest {
  domain: string
  [property: string]: any
}
export interface IDomainCheckResponse {
  exclusiveStatus: number
  linkCode: string
  [property: string]: any
}

export interface IReportDataConfigRequest {
  platform?: 'Facebook' | 'Google'
  trackKey?: string
}

export interface IReportDataConfigResponse {
  name?: string
  /**
   * 平台
   */
  platform?: string
  tagId?: string
  /**
   * key
   */
  trackKey?: string
  [property: string]: any
}

export interface IRecordAgentCodeRequest {
  /**
   * 代理code
   */
  agentCode: string
  /**
   * ip 地址
   */
  ip?: string
  [property: string]: any
}

export interface IGetAgentCodeRequest {
  /**
   * ip 地址
   */
  ip?: string
  [property: string]: any
}

export interface IGetAgentCodeResponse {
  /**
   * 代理code
   */
  agentCode: string
  /**
   * 记录 id
   */
  id: number
  /**
   * ip 地址
   */
  ip: string
  [property: string]: any
}
