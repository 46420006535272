<script setup lang="ts">
import { ref } from 'vue'
import { i18n } from '@/locale'

const {
  global: { t }
} = i18n

const getNavigationImage = (name: string): string => new URL(`/src/assets/navigation/${name}`, import.meta.url).href
const tabbarlist = ref([
  {
    id: 1,
    name: t('global.navigation.frontpage'),
    url: '/',
    active: getNavigationImage('home-active.svg'),
    inactive: getNavigationImage('home.svg')
  },
  {
    id: 2,
    name: t('global.navigation.physical'),
    url: '/sports',
    active: getNavigationImage('sports-active.svg'),
    inactive: getNavigationImage('sports.svg')
  },
  {
    id: 3,
    name: t('global.navigation.discount'),
    url: '/promo',
    active: getNavigationImage('promo-active.svg'),
    inactive: getNavigationImage('promo.svg')
  },
  {
    id: 4,
    name: t('global.navigation.order'),
    url: '/order',
    active: getNavigationImage('order-active.svg'),
    inactive: getNavigationImage('order.svg')
  },
  {
    id: 5,
    name: t('global.navigation.myinfo'),
    url: '/settings',
    active: getNavigationImage('profile-active.svg'),
    inactive: getNavigationImage('profile.svg')
  }
])
const routerActive = ref('home')
</script>

<template>
  <van-tabbar v-model="routerActive" class="nav-wrapper" :route="true">
    <van-tabbar-item v-for="item in tabbarlist" :key="item.url" class="nav-item" :to="item.url === '/order' ? { path: item.url, query: { day: 0, status: 2 } } : item.url">
      <span class="nav-title">{{ item.name }}</span>
      <template #icon="props">
        <img class="nav-pic" :src="props.active ? item.active : item.inactive" alt="" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<style scoped lang="scss">
.nav-wrapper {
  width: 100%;
  height: 59px;
  background-color: #1e1e1e;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  column-gap: 5px;
  color: #ffffff;
  .nav-item {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      width: 0px;
      //height: 4px;
      background-color: #ffffff;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 3px 3px 0px 0px;
      transition: all 0.2s linear;
    }
    .icon {
      width: 22px;
      height: 22px;
      display: block;
    }
    .name {
      font-size: 10px;
      font-weight: 700;
      text-align: center;
    }
    .nav-title {
      color: var(--Grayscale-100, #fff);
      text-align: center;

      /* 10 - Descripton, Caption/SB */

      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px; /* 150% */
    }
  }
  :deep(.van-tabbar-item--active) {
    background-color: transparent;
    &::before {
      //content: '';
      position: absolute;
      width: 40px;
      //height: 4px;
      //background-color: #ffffff;
      box-shadow: 0px -3px 30px 5px #e0c787;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 3px 3px 0px 0px;
      animation: tabActive 0.4s ease-in-out;
    }
  }

  @keyframes tabActive {
    0% {
      width: 0px;
    }
    100% {
      width: 40px;
    }
  }
}
</style>
