import { service } from '@/service'
import {
  gameAPI,
  IChildGameInfosRequest,
  IChildGameInfosResponse,
  IGameInfosResponse,
  IGameLoginRequest,
  IGameLoginResponse,
  IGetGameBalanceRequest,
  IGetGameBalanceResponse,
  IGuestLoginResponse,
  ISyncGameBalanceRequest,
  ISyncGameBalanceResponse,
  IGetLiveResponse
} from './types'

// 获取游戏平台列表
export function getGameInfos() {
  return service.get<IGameInfosResponse[]>({
    url: gameAPI.getGameInfos,
    params: { status: 0 }
  })
}

// 获取游戏平台子游戏列表
export function getChildGameInfos(data: IChildGameInfosRequest) {
  return service.post<IChildGameInfosResponse>({
    url: gameAPI.getChildGameInfos,
    data
  })
}

// 三方游戏登录 获取游戏链接
export function gameLogin(data: IGameLoginRequest) {
  return service.post<IGameLoginResponse>({
    url: gameAPI.gameLogin,
    data
  })
}

// 三方用户-游客访问
export function guestLogin(data: IGameLoginRequest) {
  return service.post<IGuestLoginResponse>({
    url: gameAPI.guestLogin,
    data
  })
}

// 三方游戏登录 余额查询
export function getGameBalance(data: IGetGameBalanceRequest) {
  return service.post<IGetGameBalanceResponse>({
    url: gameAPI.getGameBalance,
    data
  })
}

// 三方游戏登录 提取可用金额到当前游戏账户
export function syncGameBalance(data: ISyncGameBalanceRequest) {
  return service.post<ISyncGameBalanceResponse>({
    url: gameAPI.syncGameBalance,
    data
  })
}

// 提取三方可用金额到中心钱包
export function syncCenterBalance() {
  return service.post<Boolean>({
    url: gameAPI.syncCenterBalance,
    data: {}
  })
}

// 三方游戏登录 余额查询
export function getLive() {
  return service.post<IGetLiveResponse>({
    url: gameAPI.getLive,
    data: {}
  })
}
