import { defineStore } from '@/store/defineStore'
import { computed, nextTick, ref } from 'vue'
import { cache } from '@hpoker/sdk'
import {
  checkStatus as checkStatusApi,
  fetchUserInfo,
  forgetSubmit as forgetSubmitApi,
  forgetValidate as forgetValidateApi,
  getForgetValidateCode as getForgetValidateCodeApi,
  getLoginValidateCode as getLoginValidateCodeApi,
  getSmsCode as getSmsCodeApi,
  getUserBalance,
  getValidateCode as getValidateCodeApi,
  loginSubmit,
  loginValidate as loginValidateApi,
  logout as logoutApi,
  regSubmit as regSubmitApi,
  regValidate as regValidateApi,
  resetPassword as resetPasswordApi,
  setWithdrawVal as setWithdrawValApi,
  updateBirthday as updateBirthdayApi,
  updatePhone as updatePhoneApi
} from '@/service/user'
import {
  IForgetSubmitRequest,
  IGetSmsCodeRequest,
  ILoginRequest,
  IPhoneLoginRequest,
  IRegSubmitRequest,
  IResetPassword,
  ISetWithdrawValRequest,
  IupdatePhoneRequest,
  IValidateRequest,
  IValidateUpdateBirdthdayRequest
} from '@/service/user/types'
import { UserInfo } from './types'
import { deviceInfo, ID_KEYS, NATIVE_METHODS, sendToNative } from '@/utils/native'
import router from '@/router'
import { resetStoresExcept } from '@/store'
import { getDomainCheck } from '@/service/common'
import { DomainCheckStatus } from '@/service/common/types'
import useCustomerStore from '../customer'
import useFbSportsStore from '@/store/fbSports'

const { localCache } = cache

const useUserStore = defineStore('user', () => {
  const userInfoDefault = {
    email: '',
    headUrl: '',
    id: 0,
    nickName: '',
    birthday: 0,
    sex: 0,
    telArea: '',
    telephone: '',
    token: '',
    userName: '',
    vipLevel: 0,
    withdrawVal: 0,
    realName: ''
  }

  const userInfo = ref<UserInfo>(Object.assign({}))

  const balance = ref<number>(0)
  const loadingBalance = ref(false)
  const token = ref(localCache.getCache('token'))
  const lastClickedTime = ref<number>(0)
  const timer = ref<number>(0)

  const isLogin = computed(() => {
    return !!token.value
  })

  const headerQuery = ref(localCache.getCache('headerQuery'))
  const setHeaderQuery = (value: string) => {
    headerQuery.value = value
    localCache.setCache('headerQuery', value)
  }

  // console.log(localCache.getCache('headerQuery'), 'headQuery')
  const setToken = (value: string | null) => {
    token.value = value
    if (value) {
      getBalance()
    }
    cache.localCache.setCache('token', value)
  }

  // fromNative 为true时，表示数据来自native，不需要再次保存至native
  const setUserInfo = (value: UserInfo, fromNative = false) => {
    userInfo.value = value

    if (!fromNative) {
      sendToNative({
        method: NATIVE_METHODS.STORE_USERINFO,
        params: value,
        id: ID_KEYS.SET_USERINFO
      })
    }
  }

  const login = async (isPhoneLogin: boolean, params: ILoginRequest | IPhoneLoginRequest) => {
    try {
      const res = await loginSubmit(isPhoneLogin, params)
      setUserInfo(res.data)
      return res
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const loginValidate = (params: IValidateRequest) => {
    return loginValidateApi(params)
  }

  const getLoginValidateCode = (params: string) => {
    return getLoginValidateCodeApi(params)
  }

  const regValidate = (params: IValidateRequest) => {
    return regValidateApi(params)
  }

  const getRegValidateCode = (params: string) => {
    return getValidateCodeApi(params)
  }

  const regSubmit = async (params: IRegSubmitRequest) => {
    try {
      const res = await regSubmitApi(params)
      setUserInfo(<UserInfo>res.data)
      return res
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const forgetValidate = (params: IValidateRequest) => {
    return forgetValidateApi(params)
  }

  const getForgetValidateCode = (params: string) => {
    return getForgetValidateCodeApi(params)
  }

  const forgetSubmit = (params: IForgetSubmitRequest) => {
    return forgetSubmitApi(params)
  }

  const fbSportsStore = useFbSportsStore()

  const logout = async () => {
    try {
      await logoutApi()
      resetStoresExcept(['app', 'notice'])
    } catch (e) {
    } finally {
      clearInfo()
      const customerStore = useCustomerStore()
      // 退出登录时重置客服信息
      customerStore.reset()
      // 清空fb登录状态
      fbSportsStore.clearToken()
    }
  }

  const clearInfo = () => {
    userInfo.value = Object.assign({}, userInfoDefault)
    setToken(null)
    localCache.deleteCache('token')
    if (!deviceInfo.isNativePro) {
      router.push('/')
    }
  }

  const getUserInfo = async () => {
    const res = await fetchUserInfo()
    setUserInfo(res.data)
    return res
  }

  const getBalance = async () => {
    loadingBalance.value = true
    const startTime = new Date().getTime()
    if (startTime - lastClickedTime.value > 1000) {
      lastClickedTime.value = startTime
      const { data } = await getUserBalance()
      balance.value = data.balance || 0
      nextTick(() => {
        const endTime = new Date().getTime()
        if (endTime - 1000 > startTime) {
          loadingBalance.value = false
        } else {
          loading()
        }
      })
      return balance
    }
    loading()
  }

  const loading = () => {
    // 保证只有一个定时器在运行
    timer.value && clearTimeout(timer.value)
    // @ts-ignore
    timer.value = setTimeout(() => {
      loadingBalance.value = false
    }, 500)
  }

  const resetPassword = async (params: IResetPassword) => {
    return resetPasswordApi(params).then(() => {
      logout()
    })
  }

  const setWithdrawVal = async (params: ISetWithdrawValRequest) => {
    return setWithdrawValApi(params).then(() => {
      setUserInfo({ ...userInfo.value, withdrawVal: params.withdrawVal })
    })
  }

  // 检查用户状态，记录活跃用户
  const checkStatus = async () => {
    return checkStatusApi()
  }

  const getSmsCode = async (params: IGetSmsCodeRequest) => {
    return getSmsCodeApi(params)
  }

  const updateBirthday = async (params: IValidateUpdateBirdthdayRequest) => {
    return updateBirthdayApi(params)
  }

  // 代理推广code
  const agentCode = ref('')

  const setAgentCode = (value: string) => {
    agentCode.value = value
  }

  const checkDomain = async () => {
    const domain = window.location.hostname
    const { data } = await getDomainCheck({ domain })
    const { linkCode, exclusiveStatus } = data
    // 如果该主域名是专属域名，且存在linkCode，则设置linkCode
    if (exclusiveStatus === DomainCheckStatus.Exclusive && linkCode && linkCode.length > 0) {
      setAgentCode(linkCode)
    }
  }

  const showRegisterSuccessPopup = ref(false)

  // 生日格式化
  const formatDate = (inputDate: string | number) => {
    const str = String(inputDate)
    if (str.length !== 8) {
      // 输入的日期字符串长度不正确
      return 'Invalid Date'
    }

    const year = str.substring(0, 4)
    const month = str.substring(4, 6)
    const day = str.substring(6, 8)

    return `${year}-${month}-${day}`
  }

  // 更新手机号
  const updatePhone = async (params: IupdatePhoneRequest) => {
    return updatePhoneApi(params)
  }

  return {
    userInfo,
    isLogin,
    balance,
    token,
    agentCode,
    loadingBalance,
    setAgentCode,
    setToken,
    getUserInfo,
    login,
    logout,
    getBalance,
    resetPassword,
    setWithdrawVal,
    checkStatus,
    getSmsCode,
    updateBirthday,
    loginValidate,
    getLoginValidateCode,
    regValidate,
    getRegValidateCode,
    regSubmit,
    forgetValidate,
    getForgetValidateCode,
    forgetSubmit,
    clearInfo,
    setUserInfo,
    checkDomain,
    showRegisterSuccessPopup,
    formatDate,
    headerQuery,
    setHeaderQuery,
    updatePhone
  }
})

export default useUserStore
