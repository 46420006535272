import { DirectiveBinding, ObjectDirective } from 'vue'

const scrollShowElement: ObjectDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const TopBannerEl = el
    const mainDetailEl = document.getElementById(binding.value) as HTMLElement
    // 监听mainDetailEl滚动事件
    mainDetailEl?.addEventListener('scroll', function () {
      // 向下滚动隐藏 TopBannerEl 并且添加动画效果
      if (this.scrollTop > 100) {
        TopBannerEl.style.opacity = '0'
        TopBannerEl.style.transition = 'all 0.2s linear'
        TopBannerEl.style.height = '0'
        // TopBannerEl.style.padding = '0'
      } else {
        TopBannerEl.style.opacity = '1'
        TopBannerEl.style.transition = 'all 0.2s linear'
        TopBannerEl.style.height = '60px'
        // TopBannerEl.style.padding = '5px 0'
      }
    })
  },
  unmounted(el: HTMLElement, binding: DirectiveBinding) {
    const TopBannerEl = el
    const mainDetailEl = document.getElementById(binding.value)
    mainDetailEl?.removeEventListener('scroll', function () {
      TopBannerEl.style.opacity = '1'
      TopBannerEl.style.transition = 'all 0.3s linear'
      TopBannerEl.style.height = '60px'
      // TopBannerEl.style.padding = '5px 0'
    })
  }
}

export default scrollShowElement
