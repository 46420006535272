import { service } from '@/service'
import {
  GETMessageAPI,
  IGetBalanceRecordDetailRequest,
  IGetBalanceRecordDetailResponse,
  IGGetAnnouncementByReadRequest,
  IGGetAnnouncementByReadResponse,
  IGGetAnnouncementRequest,
  IGGetAnnouncementResponse,
  IGGetAnnouncementUnreadRequest,
  IGGetAnnouncementUnreadResponse,
  IHomeNotice
} from './type' // 查看消息

// 查看消息
export function getAnnounceInfos(params: IGGetAnnouncementRequest) {
  return service.get<IGGetAnnouncementResponse>({
    url: GETMessageAPI.getAnnounce,
    params
  })
}

// 是否已读
export function hasBeenRead(params: IGGetAnnouncementByReadRequest) {
  return service.get<IGGetAnnouncementByReadResponse>({
    url: GETMessageAPI.getHasbeenRead,
    params
  })
}

// 已读
export function hasRead(params: IGGetAnnouncementUnreadRequest) {
  return service.post<IGGetAnnouncementUnreadResponse>({
    url: GETMessageAPI.getHasRead,
    params
  })
}

// 获取首页跑马灯消息
export function getHomeNotice() {
  return service.get<IHomeNotice[]>({
    url: GETMessageAPI.getHomeNotice
  })
}

// 获取资金明细详情
export function getFundsDetails(data: IGetBalanceRecordDetailRequest) {
  return service.post<IGetBalanceRecordDetailResponse>({
    url: GETMessageAPI.getFundsDetails,
    data
  })
}
