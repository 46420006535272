// 语言 英文: ENG, 繁体中文: ZHO, 简体中文: CMN, 越南: VN
export enum LANGUAGES {
  ENG = 'ENG',
  ZHO = 'ZHO',
  CMN = 'CMN',
  VN = 'VN'
}

export interface IPlatformMaintenance {
  status: boolean
  startTime: number | null
  endTime: number | null
}
