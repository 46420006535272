import { defineStore } from '@/store/defineStore'
import { ref } from 'vue'
import { IPlatformMaintenance, LANGUAGES } from '@/store/app/type'
import { cache } from '@hpoker/sdk'
import { useI18n } from 'vue-i18n'
import { getDeviceId, getPlatformMaintain as getPlatformMaintainApi } from '@/service/common'
import { changePluginLocal } from '@/locale'
import { deviceInfo, ID_KEYS, NATIVE_METHODS, sendToNative, openWebViewPageSheet } from '@/utils/native'
import useUserStore from '../user'

const { localCache } = cache
const defaultLink = 'https://direct.lc.chat/16778013'

const useAppStore = defineStore('app', () => {
  const loading = ref(false)

  // const lang = ref<string>((localCache.getCache('language') as string) || LANGUAGES.ENG)
  // 默认设置为越南语
  const lang = ref<string>(LANGUAGES.VN)

  const deviceId = ref('')

  const customerServiceLink = ref(defaultLink)
  const { locale } = useI18n()
  const beforeunloadPath = ref((localCache.getCache('beforeunloadPath') as string) || '')
  const isEmpty = ref(true)

  const setBeforeunloadPath = (value: string) => {
    beforeunloadPath.value = value
    localCache.setCache('beforeunloadPath', value)
  }

  const headerQuery = ref('')
  const setHeaderQuery = (value: string) => {
    localCache.setCache('headerQuery', value)
  }

  const changeLang = (val: string) => {
    lang.value = val
    // 切换语言为对应的语言
    localCache.setCache('lang', val)
    locale.value = val
    changePluginLocal(val)
  }

  const setDeviceId = (value: string) => {
    deviceId.value = value
  }

  const getDeviceFingerPrint = async () => {
    const result = await window.nef.getToken()
    if (result.code === 200) {
      const res = await getDeviceId({ token: result.token })
      setDeviceId(`H5-${res.data.deviceId}`)
    }
    if (result.code === 201) {
      // 离线模式
      const res = await getDeviceId({ token: result.token })
      setDeviceId(`H5-${res.data.deviceId}`)
    }
  }

  const platformMaintenance = ref<IPlatformMaintenance>({
    status: false,
    startTime: null,
    endTime: null
  })

  const resetPlatformMaintenance = () => {
    platformMaintenance.value = {
      status: false,
      startTime: null,
      endTime: null
    }
  }

  const getPlatformMaintain = async () => {
    try {
      const {
        data: { status, deviceType, disableService, startTime, endTime }
      } = await getPlatformMaintainApi()
      if (status === 1 && deviceType?.includes(deviceInfo.type) && disableService === 1) {
        platformMaintenance.value = {
          status: true,
          startTime: startTime ?? null,
          endTime: endTime ?? null
        }
      } else {
        resetPlatformMaintenance()
      }
      return Promise.resolve()
    } catch (e) {
      return Promise.reject()
    }
  }

  // 安全距离
  const nativeDeviceExtraLayout = ref({
    statusBarHeight: 0,
    bottomSafeHeight: 0
  })

  const getDeviceExtraLayout = () => {
    // 非内嵌APP环境取系统提供的安全距离
    if (deviceInfo.isBrowser) {
      const sab = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')) ?? 0
      document.documentElement.style.setProperty('--bottomSafeHeight', sab + 'px')
      document.documentElement.style.setProperty('--statusBarHeight', '0px')
    } else {
      // 初始化一下安全距离的css变量
      setNativeDeviceExtraLayout(0, 0)

      sendToNative({
        method: NATIVE_METHODS.GET_EXTRA_LAYOUT,
        id: ID_KEYS.GET_EXTRA_LAYOUT
      })
    }
  }

  const setNativeDeviceExtraLayout = (statusBarHeight: number, bottomSafeHeight: number) => {
    nativeDeviceExtraLayout.value.statusBarHeight = statusBarHeight
    nativeDeviceExtraLayout.value.bottomSafeHeight = bottomSafeHeight
    document.documentElement.style.setProperty('--statusBarHeight', statusBarHeight + 'px')
    document.documentElement.style.setProperty('--bottomSafeHeight', bottomSafeHeight + 'px')
  }

  const set100vh = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }

  const handleIsEmpty = (value: boolean) => {
    isEmpty.value = value
  }

  return {
    loading,
    lang,
    customerServiceLink,
    deviceId,
    changeLang,
    getDeviceFingerPrint,
    setDeviceId,
    beforeunloadPath,
    setBeforeunloadPath,
    platformMaintenance,
    getPlatformMaintain,
    nativeDeviceExtraLayout,
    getDeviceExtraLayout,
    setNativeDeviceExtraLayout,
    set100vh,
    isEmpty,
    handleIsEmpty,
    headerQuery,
    setHeaderQuery
  }
})

export default useAppStore
