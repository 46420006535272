import { deviceInfo, ID_KEYS, NATIVE_METHODS, sendToNative } from '@/utils/native'
import { once } from 'lodash-es'
import useAppStore from '@/store/app'

export function initFingerPrint() {
  const appStore = useAppStore()
  console.log(appStore?.deviceId, 'device id')
  // 定义一个函数，用于初始化指纹
  function loadFingerPrint() {
    if (appStore?.deviceId) {
      return
    }
    // 创建一个新的 script 元素
    const script = document.createElement('script')
    // 设置 script 元素的 src 属性，指向 nefingerprint.js 文件
    script.src = '/nefingerprint.js'
    // 当脚本加载完成后，执行以下代码
    script.onload = () => {
      // 创建一个新的 NEFingerprint 实例，并将其赋值给 window.nef
      // @ts-ignore
      window.nef = createNEFingerprint({ appId: 'YD00059970718546', businessID: '21fc2adcdd7930bdfc6086d5e6fa36e7', timeout: 6000 })
      // 如果当前环境是浏览器
      if (deviceInfo.isBrowser) {
        // 如果 appStore.deviceId 不存在
        if (!appStore.deviceId) {
          // 获取设备指纹
          appStore.getDeviceFingerPrint()
        }
      } else {
        // 如果当前环境不是浏览器，向 Native 端发送一个请求，请求获取设备指纹
        sendToNative({
          method: NATIVE_METHODS.GET_DEVICE_FINGER,
          id: ID_KEYS.GET_DEVICE_FINGER
        })
      }
    }

    // 将 script 元素添加到 body 元素的末尾
    document.body.appendChild(script)
  }

  // 创建一个 PerformanceObserver 实例，用于观察性能条目
  const observer = new PerformanceObserver(
    // 使用 Lodash 的 once 函数确保回调函数只执行一次
    once((list) => {
      list.getEntries().forEach(
        once(() => {
          // requestIdleCallback(ios 800ms后执行，其他浏览器500ms后执行)
          setTimeout(loadFingerPrint, !!window.requestIdleCallback ? 500 : 800)
        })
      )
    })
  )
  // 开始观察paint 类型的性能条目(largest-contentful-paint不兼容ios)
  observer.observe({ type: 'paint', buffered: true })
}
