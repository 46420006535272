<script setup lang="ts">
import NoticeBarUI from './NoticeBarUI.vue'
import TabbarUI from './TabbarUI.vue'
import TabsUI from './TabsUI.vue'
import { useRouter, useRoute } from 'vue-router'
import AnimationButton from './animation-button.vue'
import useAppStore from '@/store/app'
import { computed } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import { deviceInfo } from '@/utils/native'
import useUserStore from '@/store/user'

const route = useRoute()
const router = useRouter()
const appStore = useAppStore()
const userStore = useUserStore()

interface Tab {
  id?: number
  name: string
  path: string
  parent?: string //用于tabs path属性和当前路径不匹配也要显示高亮的情况,如果有这种情况，需要在路由meta中添加parent属性
}
// const { current } = storeToRefs(store)
const props = defineProps({
  tabs: {
    type: Array as () => Tab[],
    required: false,
    default: () => []
  },
  showBack: {
    type: Boolean,
    default: true,
    required: false
  }
})
const reload = () => {
  router.go(0)
}
const showMainTabs = computed(() => {
  return ['game', 'order', 'promo', 'settingsIndex'].includes(route.name as string)
})

const onHomeTab = computed(() => {
  return ['game'].includes(route.name as string)
})

const showLoginPage = computed(() => {
  return ['login', 'register'].includes(route.name as string)
})

const showHeader = computed(() => {
  //native hideHeader == 1隐藏header 0不隐藏header
  userStore.setHeaderQuery('')
  if (deviceInfo.isNativePro && (route.query.hideHeader as string) !== '0') {
    // if ((route.query.hideHeader as string) !== '') {
    userStore.setHeaderQuery('1')
  } else {
    userStore.setHeaderQuery('')
  }

  if ((route.query.hideHeader as string) === '1' || ['sports'].includes(route.name as string)) {
    return false
  }

  return true
  // return !['sports'].includes(route.name as string)
})
const noLayout = computed(() => {
  return ['download', 'downloadLight'].includes(route.name as string)
})
</script>

<template>
  <template v-if="noLayout">
    <slot />
  </template>
  <template v-else-if="route.name === 'sports'">
    <slot />
    <TabbarUI></TabbarUI>
  </template>
  <template v-else>
    <div id="home-game-layout" class="base-layout" :class="route.name">
      <template v-if="showHeader">
        <div class="header-wrap">
          <NoticeBarUI />
        </div>
        <TopBanner />
      </template>
      <div
        id="main-wrap"
        class="main-wrap"
        :class="[route.name, { 'show-secondary-tabbar': !!props.tabs?.length }, { 'show-main-tabbar': showMainTabs }, { homeTab: onHomeTab }, { 'show-login-page': showLoginPage }]"
      >
        <!-- <template v-else>
        <TopBanner />
        <EmptyUI @reload="reload" />
      </template> -->
        <slot />
        <TabsUI v-if="props.tabs?.length">
          <div v-if="showBack" class="callBcak-box">
            <img class="callBack" src="@/assets/components/left_icon.svg" alt="" @click="router.back()" />
          </div>
          <AnimationButton :data="props.tabs" />
        </TabsUI>
      </div>

      <div v-if="showMainTabs" class="tabbar-wrap">
        <TabbarUI></TabbarUI>
      </div>

      <!-- 预加载icon -->
      <img v-show="false" src="@/assets/empty/404.svg" alt="" />
      <img v-show="false" src="@/assets/components/bannerLogo.png" alt="" />
    </div>
  </template>
</template>

<style scoped lang="scss">
.base-layout {
  position: relative;
  padding-top: var(--statusBarHeight);
}

.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-wrap {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 8px;
  // position: relative;
  // z-index: 1;
  // overflow-x: hidden;
  row-gap: 12px;
  padding-top: 20px;
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
  min-height: calc(var(--vh, 1vh) * 100 - 92px);

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/components/layout_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1; /* 确保背景在内容后面 */
    background-position: center center;
  }
  &.game,
  &.settingsIndex,
  &.download {
    &::before {
      display: none;
    }
  }
  &.show-main-tabbar {
    min-height: calc(var(--vh, 1vh) * 100 - 97px);
    padding-bottom: calc(88px + env(safe-area-inset-bottom));
  }

  &.show-login-page {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 16.15%), linear-gradient(180deg, rgba(0, 0, 0, 0.75) -25.07%, rgba(0, 0, 0, 0) 24.35%);
  }

  &.sports {
    padding: 0;
  }
  &.show-secondary-tabbar,
  &.orderDetail {
    height: calc(var(--vh, 1vh) * 100 - 100px);
  }
  &.game {
    padding-left: 0;
    padding-right: 0;
    :deep(.banner-contianer) {
      padding-left: 8px;
      padding-right: 8px;
    }
    background: linear-gradient(to bottom, rgba(30, 30, 30, 1), rgba(30, 30, 30, 1) 400px, white 400px, white 100%);
  }
  &.homeTab {
    background: #27272a;
  }
}

.tabbar-wrap {
  width: 100%;
  position: fixed;
  z-index: 20000;
  height: 75px;
}

.callBcak-box {
  width: 36px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #515158;
  display: flex;
  justify-content: center;
  align-items: center;
}

.callBack {
  display: block;
  width: 22px;
  height: 22px;
}

:deep(.van-hairline--top-bottom:after, .van-hairline-unset--top-bottom:after) {
  border-width: 0;
}
</style>
