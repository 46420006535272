import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'
import type { HttpRequestConfig, HttpRequestInterceptor } from './types'
import { showNotify, showToast } from 'vant'

import useAppStore from '@/store/app'
import useUserStore from '@/store/user'
import useFbSportsStore from '@/store/fbSports'
import { LoginErrorCodes } from '@/service/fbSports/types'
import { Result } from '@/service/types'
import router from '@/router'
import { deviceInfo, ID_KEYS, NATIVE_METHODS, sendToNative } from '@/utils/native'
import { i18n } from '@/locale'
import { getApiHost } from '@hpoker/sdk/dist/utils/domain'

const {
  global: { t }
} = i18n

// 默认所有接口都有loading
const default_loading = true

const TOKEN_INVALID = 3040
const TOKEN_VERIFICATION_FAILED = 11
const LOGIN_OUT = 3060
const PLATFORM_MAINTENANCE = 3068

const env = import.meta.env.VITE_APP_ENV

function showToastSmsCode(url = '', data: any) {
  const smsCodeUrls = ['/user/send/smsCode', '/client/register/validate/code', '/client/login/validate/code', '/client/forget/validate/code']
  if (!smsCodeUrls.includes(url)) return

  // 内部环境弹出验证码  方便使用
  if (env !== 'production') {
    showNotify({ type: 'success', message: `短信验证码: ${data}`, duration: 3000, className: 'ab_notify' })
  }

  showToast(t('global.captchaCode.sentSuccessfully'))
}

class HttpRequest {
  instance: AxiosInstance
  interceptors?: HttpRequestInterceptor
  showLoading?: boolean

  constructor(config: HttpRequestConfig) {
    this.instance = axios.create(config)
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? default_loading
    // @ts-ignore
    this.instance.interceptors.request.use(this.interceptors?.requestInterceptor, this.interceptors?.requestInterceptorCatch)
    this.instance.interceptors.response.use(this.interceptors?.responseInterceptor, this.interceptors?.responseInterceptorCatch)

    // 请求拦截
    this.instance.interceptors.request.use(
      (config) => {
        const appStore = useAppStore()
        const userStore = useUserStore()
        const fbSportsStore = useFbSportsStore()

        if (this.showLoading) {
          appStore.loading = true
        }

        // 设置fb体育api请求头token
        if (fbSportsStore.baseUrl && config.baseURL?.includes(fbSportsStore.baseUrl)) {
          const token = fbSportsStore.token
          if (token) {
            config.headers.Authorization = token
          }
        } else {
          // 临时添加请求头
          const commonHeader: { [k: string]: any } = {
            'os-type': deviceInfo.type,
            'device-model': 'iOS15',
            // 'User-Agent': 'apifox-',
            version: '1.0.0',
            lang: appStore.lang
          }
          commonHeader['device-id'] = 'UNKNOW'
          if (appStore.deviceId) {
            commonHeader['device-id'] = appStore.deviceId
          }

          // @ts-ignore
          config.headers = { ...config.headers, ...commonHeader }

          const token = userStore.token
          if (token) {
            config.headers.Authorization = token
          }
        }

        return config
      },
      (err) => {
        const appStore = useAppStore()
        if (this.showLoading) {
          appStore.loading = false
        }
        console.log(err)
      }
    )

    // 响应拦截
    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        const appStore = useAppStore()
        if (this.showLoading) {
          appStore.loading = false
        }
        return res.data
      },
      (error: AxiosError) => {
        const appStore = useAppStore()
        if (this.showLoading) {
          appStore.loading = false
        }
        if (error.response?.status) {
          switch (error.response.status) {
            case 401:
              break
            case 403:
              break
            case 404:
              showToast('404')
              break
            default:
              showToast(error.message)
          }
          return Promise.reject(error.response)
        }
      }
    )
  }

  request<T>(config: HttpRequestConfig<T>): Promise<T> {
    this.instance.defaults.baseURL = getApiHost()
    const appStore = useAppStore()
    const userStore = useUserStore()
    const fbSportsStore = useFbSportsStore()

    //其中的HttpRequestConfig为了解决res类型不匹配的问题
    return new Promise((resolve, reject) => {
      this.showLoading = config.showLoading ?? false
      const showErrorToast = config.showErrorToast ?? true
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }
      this.instance
        .request<any, any>(config)
        .then((res: any) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 成功响应
          if (res?.code === 0) {
            showToastSmsCode(config.url, res.data)
            resolve(res)
          } else {
            // 失败响应
            showErrorToast && showToast(res.message)

            if (res.code === PLATFORM_MAINTENANCE) {
              router.push('/maintenance')
            }

            // token失效 / 账号被踢出 / 签名认证失败
            if ([TOKEN_INVALID, LOGIN_OUT, TOKEN_VERIFICATION_FAILED].includes(res.code)) {
              userStore.clearInfo()
              fbSportsStore.clearToken()

              // 内嵌在APP时通知原生登出
              if (deviceInfo.isNative) {
                sendToNative({
                  method: NATIVE_METHODS.TOKEN_EXPIRED,
                  id: ID_KEYS.TOKEN_EXPIRED,
                  params: { message: res.message }
                })
              }

              // 新版原生app内嵌时token失效不重定向首页
              if (!deviceInfo.isNativePro) {
                router.push('/')
              }
            }

            // 处理fb体育登录失效
            if (fbSportsStore.baseUrl && config.baseURL?.includes(fbSportsStore.baseUrl)) {
              const loginErrorCodes = Object.values(LoginErrorCodes).filter((value) => typeof value === 'number')
              if (loginErrorCodes.includes(res.code)) {
                userStore.clearInfo()
                fbSportsStore.clearToken()
              }
            }

            reject(res)
          }
        })
        .catch((error) => {
          // appStore.handleIsEmpty(true)
          reject(error)
        })
        .finally(() => {
          if (this.showLoading) {
            appStore.loading = false
          }
        })
    })
  }

  get<T>(config: HttpRequestConfig): Promise<Result<T>> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.request<Result<T>>({ ...config, method: 'get' })
  }

  post<T>(config: HttpRequestConfig): Promise<Result<T>> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.request<Result<T>>({ ...config, method: 'post' })
  }
}

export default HttpRequest
