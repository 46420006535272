import metaPixel from './metaPixel'
import googleAnalytics from './googleAnalytics'
import tiktokPixel from './tiktokPixel'

export type TEventName =
  | 'open'
  | 'firstOpen'
  | 'registerSubmit'
  | 'registerSuccess'
  | 'depositSubmit'
  | 'firstDepositSubmit'
  | 'firstDepositOpen'
  | 'depositOpen'
  | 'getRegistrationVerifyCode'
  | 'depositArrive'
  | 'withdrawArrive'
  | 'firstDepositArrive'
  | 'submitRegistrationVerifyCode'
  | 'downloadApp'
  | 'firstDeposit'
  | 'betBonus'
  | 'slotBetBonus'
  | 'invite'
  | 'rebate'
  | 'midAutumn'
  | 'sportsBetting'

type TAgent = {
  name: string
  recordEvent: (eventName: TEventName, eventValue?: any) => void
}

class RecordEvent {
  agents: TAgent[] = []

  addAgent(agent: any) {
    this.agents.push(agent)
  }

  record(eventName: TEventName, eventValue?: any) {
    this.agents.forEach((agent) => {
      agent.recordEvent(eventName, eventValue)
    })
  }
}

const recordEvent = new RecordEvent()
export default recordEvent

recordEvent.addAgent(metaPixel)
recordEvent.addAgent(googleAnalytics)
recordEvent.addAgent(tiktokPixel)
