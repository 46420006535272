// 查看消息
export enum GETMessageAPI {
  getAnnounce = '/message/getNotification',
  getHasbeenRead = '/message/getUnReadCount',
  getHasRead = '/message/updateNotificationByRead',
  getHomeNotice = '/client/notification/getPostNotificationList',
  getFundsDetails = '/fund/userAccount/balanceRecordDetail'
}

export interface IGGetAnnouncementResponse {
  code: number
  data: Data
  message: string

  [property: string]: any
}

export interface IGGetAnnouncementRequest {
  messageType: number

  [property: string]: any
}

export type IGGetAnnouncementByReadResponse = number

export interface IGGetAnnouncementByReadRequest {
  [property: string]: any
}

export interface IGGetAnnouncementUnreadResponse {
  code?: number
  data?: number
}

export interface IGGetAnnouncementUnreadRequest {
  id: number
  messageType: number
}

export interface Data {
  current: number
  pages: number
  records: IGGetAnnouncementRecord[]
  size: number
  total: number
  totalType: number

  [property: string]: any
}

export interface IGGetAnnouncementRecord {
  createTime?: number
  id: number

  linkParameters: string
  message: string

  messageType: number

  status?: number
  title: string
  /**
   * 更新时间
   */
  updateTime?: number
  /**
   * 首页强弹窗展示
   */
  isPopup: boolean

  [property: string]: any
}

export interface IHomeNotice {
  createTime?: number
  id?: number
  /**
   * 消息
   */
  message?: string
  /**
   * 标题
   */
  title?: string
  updateTime?: number

  [property: string]: any
}

export interface IGGetNoticeRecord {
  createTime?: number
  id: number

  linkParameters: string
  message: string

  messageType: number

  status?: number
  title: string
  /**
   * 更新时间
   */
  updateTime?: number

  [property: string]: any
}

export class IGetBalanceRecordDetailRequest {}

export class IGetBalanceRecordDetailResponse {}
