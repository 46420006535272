import { App } from 'vue'
import { createI18n } from 'vue-i18n'
import viVN from './languages/vi-VN'
import ENG from './languages/ENG'
import zhCN from './languages/zh-CN'

import { cache } from '@hpoker/sdk'
import { LANGUAGES } from '@/store/app/type'
import { Locale as VantLocale } from 'vant'
import vantViVN from 'vant/es/locale/lang/vi-VN'
import vantZhCN from 'vant/es/locale/lang/zh-CN'
import vantENG from 'vant/es/locale/lang/en-US'
import 'dayjs/locale/vi'
import 'dayjs/locale/en.js'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs' //  相关插件语言包切换
const env = import.meta.env.VITE_APP_ENV

//  相关插件语言包切换
export function changePluginLocal(value: string) {
  switch (value) {
    case LANGUAGES.CMN:
      VantLocale.use('zh-CN', vantZhCN)
      dayjs.locale('zh-cn')
      break
    case LANGUAGES.VN:
      VantLocale.use('vi-VN', vantViVN)
      dayjs.locale('vi')
      break
    case LANGUAGES.ENG:
      VantLocale.use('en-us', vantENG)
      dayjs.locale('en')
      break
  }
}

const { localCache } = cache

const messages = {
  [LANGUAGES.CMN]: zhCN,
  [LANGUAGES.VN]: viVN,
  [LANGUAGES.ENG]: ENG
}

const defaultLocal = LANGUAGES.VN
// 如果是生产环境，就只使用越南语语言，否则优先使用缓存的语言
const locale = env === 'production' ? defaultLocal : localCache.getCache('app')?.lang || defaultLocal

changePluginLocal(locale)

export const i18n = createI18n({
  globalInjection: true, //全局生效$t
  locale,
  fallbackLocale: defaultLocal, // 回退 首选语言缺少翻译时要使用的语言
  messages,
  legacy: false
})

const install = (app: App) => {
  app.use(i18n)
}

export default {
  install
}
