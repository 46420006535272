import helpText from '@/views/help/betRules/helpText.json'
import pages from '@/views/help/betRules/pages.json'

export default {
  // 全局
  'global.home': '首页',
  'global.sports': '体育',
  'global.electronic': '电子',
  'global.gaming': '电竞',
  'global.lottery': '彩票',
  'global.realPerson': '真人',
  'global.cards': '棋牌',
  'global.fishing': '捕鱼',
  'global.cockfighting': '斗鸡',
  'global.promotion': '优惠',
  'global.order': '注单',
  'global.profile': '我的',
  'global.deposit': '充值',
  'global.withdraw': '提现',
  'global.helpCenter': '帮助中心',
  'global.confirm': '确定',
  'global.cancel': '取消',
  'global.delete': '删除',
  'global.sentSuccessfully': '发送成功！',
  'global.operationSuccessful': '操作成功！',
  'global.operationsFrequent': '操作频繁！',
  'global.copySuccessful': '复制成功！',
  'global.contactService': '联系客服',
  'global.customerService': '在线客服',
  'global.contactServiceTips': '如您有任何疑问，请联系我们的在线客服。',
  'global.noData': '暂无数据',
  'global.reload': '重新加载',
  'global.failedToLoad': '加载失败',
  'global.failedToLoadTips': '暂时没有数据可以访问',
  'global.loading': '加载中',
  'global.tips': '温馨提示',
  'global.telephone.placeholder': '请输入手机号',
  'global.telephone.validate': '请输入正确手机格式',
  'global.captchaCode.isSent': '请先获取验证码',
  'global.captchaCode.placeholder': '请输入验证码',
  'global.captchaCode.validate': '请输入验证码',
  'global.captchaCode.sentSuccessfully': '验证码发送成功！',
  'global.username.placeholder': '请输入6-16个字母及数字组合',
  'global.username.validate': '请输入6-16个字母及数字组合',
  'global.password.placeholder': '请输入8-20个字母及数字组成的密码',
  'global.password.validate': '请输入8-20个字母及数字组成的密码',
  'global.navigation.frontpage': '首页',
  'global.navigation.physical': '体育',
  'global.navigation.discount': '优惠',
  'global.navigation.order': '注单',
  'global.navigation.myinfo': '我的',
  'global.noMore': '没有更多了',
  'global.all': '全部',
  'global.add': '添加',
  'global.exitAppToast': '再按一次退出应用',
  'global.defaultErrorToast': '抱歉，出了点错，请稍后再试！',
  'global.openWindow.errorTips': '打开窗口失败，请关闭浏览器弹窗拦截！',

  // 全局组件 components/**
  'global.captchaButton.getCode': '获取验证码',
  'global.captchaButton.sendingCode': '正在发送验证码',
  'global.captchaButton.resend': '重新发送',
  'global.captchaButton.countdown': '{count}s(重新发送)',
  'global.captchaPhone.title': '验证手机号',
  'global.captchaPhone.tips': '为了您的账户和资金安全，请先完成手机验证',
  'global.captchaPhone.service': '如需修改手机号，请{e}',

  // banner
  'home.banner.readMore': 'read more',

  // 登录、注册、忘记密码
  'login.title': '登录',
  'login.loginPhone': '手机号登录',
  'login.loginAccount': '账号密码登录',
  'login.loginSuccessToast': '登录成功',
  'login.alreadyHaveAccount': '已有账号？去{e}',
  'login.username.placeholder': '请输入用户名',
  'login.password.placeholder': '请输入密码',
  'register.register': '注册',
  'register.title': '账号注册',
  'register.success': '注册成功！',
  'register.successPopup.primaryText': '恭喜您已成功注册AB77账户。',
  'register.successPopup.subText': '首次存款只需6倍流水即可获得高达 3,000,000 VND 的奖金。',
  'register.successPopup.button': '立即存款',
  'forget.title': '忘记密码',
  'forget.checkPassword.placeholder': '请确认新密码',
  'forget.checkPassword.validate': '两次输入密码不一致!',
  'login.frequentErrors': '短信验证码频繁错误，已限制使用{m}分钟',
  'login.tab.guest': '注册',
  'login.tab.member': '登陆',
  'login.phone.title': 'use mobile number or username to proceed login',
  'login.username.title': 'use mobile number or username to proceed login',
  'reset.password': '忘记密码',
  'reset.password.rule': '密码需要根据8-20个字母和数字或符号创建',
  'reset.change': 'change',
  'login.username.rule': 'username need including 6-16 alphabet and number',
  'login.welcome': 'welcome,guest',
  'login.form.welcome': 'welcome,',
  'login.form.guest': '登陆',
  'phone.errorMessages': 'errorMessages',
  'phone.code.errorMessages': 'errorMessages',
  'placeholder.password': '密码',
  'placeholder.username': '账号',
  'register.agentCode': '推广码',
  'register.agentCode.placeholder': '若有推广码请填写此栏位（非必填）',
  'register.agentCode.errorMessage': '推广码由4-10位英文+数字组成',

  // 首页
  'home.notLogin.welcome': '欢迎您，亲爱的用户',
  'home.notLogin.login': '请登录',
  'home.game.maintenance': '维护中',
  'home.game.comingSoon': '即将上线',
  'home.game.recommend': '推荐',
  'home.game.enterGame': '立即进入游戏',
  'home.game.new': '新上线',
  'home.game.maintenanceToast': '该游戏正在维护中！',
  'home.game.comingSoonToast': '即将上线, 敬请期待！',
  'home.game.transferError': '若您进入的游戏未展示正确的余额，您可尝试关闭游戏页，重新进入游戏',
  'home.game.sublist': '游戏列表',
  'home.banner.explore': 'Explore all games',
  'home.banner.jump': 'read more',
  'home.title.loginText': 'login to experience now',
  'home.tab.banner': "what's new",
  'home.tab.category': 'category',
  'home.title.walletTop': 'Wallet',

  // 首页快速投注
  'home.quickBet.loadMore': '加载更多',
  'home.quickBet.createBetSlip': '创建投注单',
  'home.quickBet.betSlip': '投注单',
  'home.quickBet.betSlip.balance': '余额：',
  'home.quickBet.betSlip.bet': '投注',
  'home.quickBet.betSlip.confirm': '确认',
  'home.quickBet.betSlip.bettingAmount.limit': '限额：',
  'home.quickBet.betSlip.bettingOdds': '投注赔率',
  'home.quickBet.betSlip.bettingAmount': '投注金额',
  'home.quickBet.betSlip.winnable': '可赢额',
  'home.quickBet.betSlip.totalAmount': '总投注额',
  'home.quickBet.betSlip.confirming': '确认中',
  'home.quickBet.betSlip.confirmed': '已确认',
  'home.quickBet.betSlip.rejected': '投注失败',
  'home.quickBet.betSlip.successful': '投注成功',
  'home.quickBet.betSlip.closed': '不可投注，盘口已关闭',
  'home.quickBet.inProgress': '进行中',

  // 充值、提现
  'wallet.name': 'VÍ',
  'wallet.step': 'BƯỚC',
  'wallet.step1.title.up': 'CHỌN HÌNH THỨC THANH TOÁN',
  'wallet.step2.title.up': 'NHẬP SỐ TIỀN',
  'wallet.step3.title.up': 'ĐIỀN TÊN ĐĂNG KÝ CỦA BẠN',
  'wallet.step1.title.down': 'CHỌN TÀI KHOẢN NHẬN TIỀN',
  'wallet.step2.title.down': 'NHẬP SỐ TIỀN',
  'wallet.up.tips': '确保你的付款金额与订单金额一致',
  'wallet.amountInput.placeholder': '请输入{typeName}金额',
  'wallet.amountInput.limit': '限额{minAmount}～{maxAmount}元',
  'wallet.balancePanel.title': '我的余额',
  'wallet.balancePanel.withdrawBalance': '可提现金额：',
  'wallet.information.noOrder': '暂无订单',
  'wallet.information.orderInfo': '订单信息',
  'wallet.information.orderId': '订单号',

  'wallet.deposit.doc': '充值教程',
  'wallet.deposit.formPayLabel': '充值方式',
  'wallet.deposit.formAmountLabel': '充值金额',
  'wallet.deposit.formInfoLabel': '信息完善',
  'wallet.deposit.formInfoPlaceholder': '请输入汇款账号的持有人姓名',
  'wallet.deposit.amountValidate': '请填写充值金额',
  'wallet.deposit.limitValidate': '请填写限额内的金额',
  'wallet.deposit.nameValidate': '请填写持卡人姓名',
  'wallet.deposit.nameValidateReg': '请输入正确格式的汇款卡的持卡人姓名！',
  'wallet.deposit.submit': '立即充值',
  'wallet.deposit.noDataTitle': '暂无可用充值方式',
  'wallet.deposit.noDataText': '给您造成的不便，敬请谅解',
  'wallet.deposit.information.payInfo': '付款信息',
  'wallet.deposit.information.payQrCode': '收款银行二维码',
  'wallet.deposit.information.cancel': '撤销当前充值',
  'wallet.deposit.information.confirmCancel': '继续撤销',
  'wallet.deposit.information.cancelTips': '若您已完成付款请勿撤销，否则可能造成您该笔交易的资金丢失',
  'wallet.deposit.information.cancelSubTips': '请耐心等待，我们将尽快为您处理',
  'wallet.deposit.information.inProgress': '充值进行中',
  'wallet.deposit.information.time': '充值时间',
  'wallet.deposit.information.owner': '收款账户名',
  'wallet.deposit.information.bankcardNo': '收款账号',
  'wallet.deposit.information.bankName': '收款银行',
  'wallet.deposit.information.bankBranch': '支行名称',
  'wallet.deposit.information.keyword': '附言',
  'wallet.deposit.information.countdownText': '请在{minute}分{second}秒前完成付款，付款金额必须于订单金额一致',
  'wallet.deposit.information.countDown': 'Vui lòng hoàn tất thanh toán trong',
  'wallet.deposit.information.popup.comfirm': 'CÓ',
  'wallet.deposit.information.popup.cancel': 'KHÔNG',
  'wallet.deposit.information.popup.usdtAmount': '到账USDT',
  'wallet.deposit.UsdtAmount': 'USDT金额',
  'wallet.deposit.rechargeAddress': '充值地址',

  // 收款地址
  'profile.payment.crypto.tips.title': '最多可添加5个虚拟币地址',
  'profile.payment.crypto.bank.save': '虚拟币',
  'profile.payment.crypto.noData': '暂无收款地址',
  'profile.payment.crypto.tips': '请输入您的全名，必须与您的银行账户持有人姓名相同，否则存款将被拒绝。',
  'profile.payment.crypto.title': '收款地址',
  'profile.payment.crypto.cardHolder': '持卡人',
  'profile.payment.crypto.placeholder.comfirmSignature': '请确保输入持卡人姓名',
  'profile.payment.crypto.placeholder.signature': '请填写持卡人姓名',
  'profile.payment.crypto.openingBank': '协议',
  'profile.payment.crypto.openingBank.select': '钱包地址',
  'profile.payment.crypto.alias': '别名',
  'profile.payment.crypto.pickCards': '',
  'profile.payment.crypto.addCards.success': '添加地址成功',
  'profile.payment.crypto.realName': '别名',
  'profile.payment.crypto.cardNnumber': '地址',
  'profile.payment.crypto.placeholder.cardNumber': '请填写地址',
  'profile.payment.crypto.placeholder.alias': '请填写别名',
  'profile.payment.crypto.tips.comfirm.title': '请确认所有信息是否正确，错误信息会导致无法到账',
  'profile.payment.crypto.bank': '虚拟币地址',
  'profile.payment.crypto.hello': '您好',
  'profile.payment.crypto.comfirm.notAddCard': '添加虚拟币地址',
  'profile.payment.crypto.comfirm.addCard': '确认添加虚拟币地址',
  'profile.payment.crypto.tips.deleteCard.success': '删除虚拟币地址成功',
  'profile.payment.tab.fiat': '法币',
  'profile.payment.tab.cryptocurrency': '虚拟币',

  'wallet.withdraw.manual': '提现说明',
  'wallet.withdraw.doc': '提现教程',
  'wallet.withdraw.bankCard': '到账银行',
  'wallet.withdraw.noCard': '您还未添加银行卡',
  'wallet.withdraw.amount': '提现金额',
  'wallet.withdraw.information.inProgress': '提现进行中',
  'wallet.withdraw.information.bankCardNo': '提现卡号',
  'wallet.withdraw.information.time': '提现时间',
  'wallet.withdraw.information.bankName': '提现银行',
  'wallet.withdraw.unableWithdraw': '您暂时还不可提现',
  'wallet.withdraw.leftAmount': '剩余流水',
  'wallet.withdraw.unableWithdrawForLevel': '当前会员层级不可提现',
  'wallet.withdraw.maintenance': '提现维护',
  'wallet.withdraw.noCountLimit': '今日提现次数已经用完，敬请明日再来',
  'wallet.withdraw.noAmountLimit': '本次提现额度不足，今日提现余额度剩余',
  'wallet.withdraw.prcoeed': 'nhấn vào đây để liên hệ với bộ phận hỗ trợ khách hàng của chúng tôi để tìm giải pháp trước khi bạn tiếp tục',
  'wallet.withdraw.withdrawAmount': '本次提现金额',
  'wallet.withdraw.charge': '手续费',
  'wallet.withdraw.expectedReceipt': '预计到账',
  'wallet.withdraw.viewWithdrawManual': '查看提现说明',
  'wallet.withdraw.amountValidate': '请填写提现金额',
  'wallet.withdraw.insufficientAmountValidate': '可提现金额不足',
  'wallet.withdraw.limitValidate': '请填写限额内的金额',
  'wallet.CASHOUTAMOUNT': 'SỐ TIỀN RÚT',
  'wallet.RequestedAmount': '请求金额',
  'wallet.receivedAmount': '到账金额',
  'wallet.CashOutAmount': 'số tiền rút',
  'wallet.handlingfee': 'phí xử lý được áp dụng khi bạn rút tiền',
  'wallet.INSUFFICIENTBALANCE': 'SỐ DƯ TIỀN KHÔNG ĐỦ',
  'wallet.point': 'số dư tiền không đủ để thực hiện lệnh rút',
  'wallet.legend': 'press here to access tutorial for solution before you proceed',
  'wallet.currentBalance': 'Available cash out amount',
  'wallet.LOADUNSUCCESSFUL': 'LOAD UNSUCCESSFUL',
  'wallet.nodata': 'No data avaiable for the moment',
  'wallet.reload': 'RELOAD',
  'wallet.GETHELP': 'TRỢ GIÚP',
  'wallet.noMethod': 'NO AVAILABLE RELOAD METHODS',
  'wallet.noMethodTips': 'We apologize for any inconvenience caused. If you have any questions, please contact our online customer service.',
  'wallet.amountInput.tips': 'vui lòng điền tên bạn đã đăng ký vào phương thức thanh toán bạn đã chọn',
  'wallet.withdraw.noCryptoCurrency': '您还未添加虚拟币地址',
  'wallet.withdraw.information.protocol': '协议',

  //注单记录
  'betting.validStatement': '有效流水：',
  'betting.unit': '单',
  'betting.paid': '已结算',
  'betting.noPaid': '未结算',
  'betting.canceled': '已取消',
  'betting.coinType': '币种：',
  'betting.collusion': '串关',
  'betting.principal': '本金：',
  'betting.validBet': '有效投注',
  'betting.betTime': '投注时间',
  'betting.betNumber': '投注单号',
  'betting.roundNo': '期号',
  'betting.open': '展开',
  'betting.close': '收起',
  'betting.gameName': '子游戏名',
  'betting.result': '输赢: ',
  'betting.total': '总计(单): ',
  'betting.betContent': '投注内容',
  'betting.maxCanWinAmmount': '最大可赢',
  'betting.noOpen': '未开奖',
  'betting.winning': '中奖',
  'betting.noWin': '未中奖',
  'betting.cancel': '取消',
  'betting.betFail': '投注失败',
  'betting.win': '赢',
  'betting.lose': '输',
  'betting.halfLose': '输半',
  'betting.halfWin': '赢半',
  'betting.tie': '和',
  'betting.earlySettlement': '提前结算',
  'betting.noResults': '未结算',
  'betting.noData': 'no content display here',
  'betting.betAmount': '投注额',

  'betting.summary.winlose': '输/赢',
  'betting.summary.amount': '金额',
  'betting.summary.spend': '有效投注额',

  'betting.subGame': '子游戏',
  'betting.tableNumber': '桌号',
  'betting.bureauNumber': '局号',
  'betting.roomNumber': '房间号',
  'betting.issue': '期号',
  'betting.section': '场次',
  'betting.raceName': '赛事名',
  'betting.betrayal': '投注明细',
  'betting.option': '选项',
  'betting.seatNumber': '座位号',
  'betting.magnification': '倍率',
  'betting.odds': '赔率',
  'betting.multiple': '倍数',

  // 收款地址
  'profile.payment.tips.title': '最多可添加5张银行卡',
  'profile.payment.bank.save': '储蓄卡',
  'profile.payment.noData': '暂无收款地址',
  'profile.payment.title': '收款地址',
  'profile.payment.cardHolder': '持卡人',
  'profile.payment.placeholder.comfirmSignature': '请确保输入持卡人姓名',
  'profile.payment.placeholder.signature': '请填写持卡人姓名',
  'profile.payment.openingBank': '开户行',
  'profile.payment.openingBank.select': '选择开户行',
  'profile.payment.pickCards': '选择银行卡',
  'profile.payment.addCards.success': '添加银行卡成功',
  'profile.payment.realName': '姓名',
  'profile.payment.cardNnumber': '卡号',
  'profile.payment.placeholder.cardNumber': '请填写卡号',
  'profile.payment.tips.comfirm.title': '请确认所有信息是否正确，错误信息会导致无法到账',
  'profile.payment.bank': '银行卡',
  'profile.payment.hello': '您好',
  'profile.payment.comfirm.addCard': '确认添加银行卡',
  'profile.payment.tips.deleteCard.success': '删除银行卡成功',
  'profile.payment.addBank': 'Thêm tài khoản ngân hàng',
  'profile.payment.transaction': 'transaction',
  'profile.payment.transaction.step1': 'STEP 1 >',
  'profile.payment.transaction.step2': 'STEP 2 >',
  'profile.payment.transaction.step3': 'STEP 3 >',
  'profile.payment.transaction.accountDetail': 'account detail',
  'profile.payment.transaciton.warning': 'deposit will be rejected, if the detail you fill in is different with the detail you registered from bank',
  'profile.payment.transaciton.proceed': 'proceed',
  'profile.payment.transaciton.confirm': 'confirm',
  'profile.payment.transaciton.confirmation': 'confirmation',
  'profile.payment.transaciton.OTPverification': 'OTP verification',
  'profile.payment.transaciton.OTPwarning': 'Nhận OTP từ số đăng ký {phone} để hoàn tất xác thực',
  'profile.payment.transaciton.completed': 'completed',
  'confirm-button-text': 'Xác nhận',
  'cancel-button-text': 'Hủy bỏ',
  'wallet.deposit.numberValidateReg': 'Vui lòng điền chính xác số Tài Khoản Ngân Hàng.',

  //资金明细
  'profile.funding.process': '进行中',
  'profile.funding.success': '成功',
  'profile.funding.fail': '失败',
  'profile.funding.all': '全部',
  'profile.funding.recharge': '充值',
  'profile.funding.rechargeByOther': '代充',
  'profile.funding.withdrawl': '提现',
  'profile.funding.transfer': '转账',
  'profile.funding.dividend': '红利',
  'profile.funding.rebate': '返水',
  'profile.funding.addCoin': '加币',
  'profile.funding.deleteCoin': '减币',
  'profile.funding.redPacket': '红包',
  'profile.funding.sendGifts': '打赏',
  'profile.funding.cancel': '退回',
  'profile.funding.statement.updated': '调整完成流水',
  'profile.funding.statement.clear': '流水清零',
  'profile.funding.loseUp.clear': '输光清零',
  'profile.funding.unknow': '未知',
  'profile.funding.today': '今日',
  'profile.funding.yesterday': '昨日',
  'profile.funding.7days': '7日',
  'profile.funding.30days': '30日',
  'profile.funding.orderNumber': '订单号',
  'profile.funding.dealTime': '交易时间',
  'profile.funding.dealType': '充提类别',
  'profile.funding.subtype': '充提来源',
  'profile.funding.status': '状态',
  'profile.funding.startAmount': '发起金额',
  'profile.funding.receivedAmount': '到账金额',
  'profile.funding.receivedUSDT': '到账虚拟币',

  // 帮助中心
  'profile.helpCenter': '帮助中心',
  'profile.helpCenter.feedBack': '意见反馈',
  'profile.helpCenter.aboutUs': '关于我们',
  'profile.helpCenter.upperTitle': 'Xin chào thành viên thân yêu',
  'profile.helpCenter.lowerTitle': 'Chào mừng đến với trung tâm trợ giúp',
  'profile.helpCenter.descriptionTitle': 'Đường dây nóng 24/7, phục vụ tận tâm, luôn sẵn lòng phục vụ bạn mọi lúc mọi nơi ~',
  'profile.helpCenter.rechargeTutorial': 'HƯỚNG DẪN NẠP TIỀN',
  'profile.helpCenter.rechargeTutorialDescription': 'Lối chơi tuyệt vời,chờ bạn khám phá',
  'profile.helpCenter.withdrawTutorial': 'HƯỚNG DẪN RÚT TIỀN',
  'profile.helpCenter.withdrawTutorialDescritpion': 'Lối chơi tuyệt vời,chờ bạn khám phá',
  'profile.helpCenter.sportTutorial': 'Hướng dẫn cách chơi thể thao',
  'profile.helpCenter.sportTutorialDescription': 'Lối chơi tuyệt vời,chờ bạn khám phá',
  'profile.helpCenter.simBet': 'Đặt cược thể thao mô phỏng',
  'profile.helpCenter.simBetDescription': 'Không biết cược,nhanh đến xem nào',
  'profile.helpCenter.betRule': 'HƯỚNG DẪN CÁ CƯỢC THỂ THAO',
  'profile.helpCenter.betRuleDes': 'Hiểu rõ quy tắc,thuận tiện đặt cược',
  'profile.helpCenter.MOMO': 'MOMO',
  'profile.helpCenter.QRCodeWithdraw': 'Mã quét thẻ ngân hàng',
  'profile.helpCenter.BankAccountTransfer': 'Chuyển khoản thẻ ngân hàng',

  // 个人中心
  'profile.login': '登录即可查看',
  'profile.balance': '我的余额',
  'profile.userHeader.prefix': '您好,',
  'profile.userHeader.userInfo': '个人信息',
  'profile.userHeader.checkLogin': '登录可享受更多服务',
  'profile.menu.fundDetails': '资金明细',
  'profile.menu.fundDetails.details': '资金详情',
  'profile.menu.betRecord': '注单记录',
  'profile.menu.messageCenter': '消息中心',
  'profile.menu.paymentAddress': '收款地址',
  'profile.menu.vipCenter': 'VIP中心',
  'profile.menu.helpCenter': '帮助中心',
  'profile.noticeBar.deposit': '当前有一笔进行中的充值订单',
  'profile.noticeBar.withdraw': '当前有一笔进行中的提现订单',

  // 消息中心
  'profile.message.title': '消息',
  'profile.message.details': '详情',
  'profile.message.announcement': '公告',
  'profile.message.announChange': '账变通知',

  // 修改登录密码
  'profile.modify.title': '修改登录密码',
  'profile.modify.setPassword': '设置新密码',
  'profile.modify.currentPassword': '请输入当前密码',
  'profile.modify.passwordHint': '请入8-20个字母及数字组成的密码',
  'profile.modify.comfirmPassword': '请确认新密码',
  'profile.modify.comfirm': '确定',
  'profile.modify.passwordFormat': '当前密码格式不对',
  'profile.modify.passwordDifferent': '两次密码输入不一致',
  'profile.modify.tips': '密码已修改，请重新登录',

  // 个人信息
  'profile.user.accountName': '账户名',
  'profile.user.registerDate': '注册日期',
  'profile.user.phoneNumber': '手机号',
  'profile.user.phoneTips': '已绑定的手机号如需修改请联系客服',
  'profile.user.loginPassword': '登录密码',
  'profile.user.revise': '修改',
  'profile.user.verify': '提现短信验证',
  'profile.user.dateBirth': '出生日期',
  'profile.user.addDate': '添加日期，以确保您已满18周岁',
  'profile.user.editDate': '出生日期暂不可修改',
  'profile.user.signOut': '退出登录',
  'profile.user.selectDate': '选择日期',

  // 优惠活动
  'promotion.activity.select': '领取',
  'promotion.activity.selected': '已领取',
  'promotion.activity': '优惠活动',
  'promotion.details.claimBonus': '领取奖金',
  'promotion.details.joinNow': '立即参加',
  'promotion.details.notAllowed': '不可参与',
  'promotion.details.notAchieved': '未达标',
  'promotion.details.receiving': '领取中',
  'promotion.details.received': '已领取',
  'promotion.details.reviewing': '审核中',
  'promotion.details.rejected': '已拒绝',
  'promotion.details.invalid': '失效',
  'promotion.detailsDesc.time': '活动时间',
  'promotion.detailsDesc.longTermEffective': '长期',
  'promotion.detailsDesc.howToParticipate': '参与对象',
  'promotion.detailsDesc.allMembers': '全体会员',
  'promotion.detailsDesc.applicablePlatforms': '适用平台',
  'promotion.detailsDesc.allPlatforms': '所有平台',
  'promotion.detailsDeposit.depositPromotionFirstTitle': '首次存款优惠',
  'promotion.detailsDeposit.depositPromotionSecondTitle': '第二次存款优惠',
  'promotion.detailsDeposit.depositPromotionFirstLimit': '第一次',
  'promotion.detailsDeposit.depositPromotionBonusRatio': '奖金比例',
  'promotion.detailsDeposit.depositPromotionMaxAmount': '最大奖金',
  'promotion.detailsDeposit.depositPromotionBetFactor': '投注倍数',
  'promotion.detailsDeposit.depositBonusFirstTitle': '首存奖金详情',
  'promotion.detailsDeposit.depositBonusSecondTitle': '第二次存款奖金详情',
  'promotion.detailsDeposit.depositBonusFirstAmount': '首存金额',
  'promotion.detailsDeposit.depositBonusSecondAmount': '第二次\n存款金额',
  'promotion.detailsDeposit.realPerson': '真人/电子/捕鱼',
  'promotion.detailsDeposit.otherVenues': '其他场馆',
  'promotion.detailsDeposit.multiple': '倍',
  'promotion.detailsDeposit.depositBonusRatio': '奖金比例',
  'promotion.detailsDeposit.depositBonusAmount': '赠送金额',
  'promotion.detailsDeposit.depositBonusBet': '需要流水',
  'promotion.detailsBonus.receivedSuccessful': 'Bạn đã nhận {amount} tiền thưởng ,Chỉ cần hoàn thành 3 vòng cược có thể rút',
  'promotion.detailsDeposit.orMore': '或更多',
  'promotion.detailsShareJackpot.time': '时间',
  'promotion.detailsShareJackpot.peopleNum': '瓜分奖池人数',
  'promotion.detailsBonus.betAmountLimit': '投注额要求',
  'promotion.detailsBonus.amountReward': '奖金金额',
  'promotion.detailsBonus.negativeProfit': '负盈利',
  'promotion.detailsBonus.bonusAmount': '奖金金额',
  'promotion.detailsRebate.effectiveAmount': '有效投注额',
  'promotion.detailsRebate.rebateRatio': '返水比例',
  'promotion.detailsRebate.limit': '上限',
  'promotion.detailsBonusTips.welcome': 'Dear value {name} users, Your total bet last week was:',
  'promotion.detailsBonusTips.betBonuswelcome': 'Bạn {name} thân mến, lợi nhuận âm từ trò chơi đánh bạc của bạn ngày hôm qua:',
  'promotion.detailsBonusTips.validBetAmount': '您的上周总投注额为：',
  'promotion.detailsBonusTips.negativeProfit': '您的昨天的电子负盈利金额为：',
  'promotion.detailsRules.title': '活动规则',
  'promotion.summary.firstDepositTitle': '新会员存款高达60%礼金',
  'promotion.summary.rebateTitle': 'UP TO 1.2% CASHBACK',
  'promotion.summary.shareTheJackpotTitle': 'DAILY BONUS ONE BILLION VIETNAM DONG GRAND',
  'promotion.summary.betBonusTitle': '每周投注奖金最高可达8.888Tr',
  'promotion.summary.slotBetBonusTitle': 'SLOT MACHINE RELIEF FUND',
  'promotion.details.firstDepositSubTitle': '立马存款与领取礼金',
  'promotion.details.rebateSubTitle': 'Thiết lập chuẩn mực mới',
  'promotion.details.shareTheJackpotSubTitle': 'Hãy tham gia cá cược thể thao ngay bây giờ!',
  'promotion.details.betBonusSubTitle': 'Hãy đến cược ngay và dành chiến thắng',
  'promotion.details.slotBetBonusSubTitle': 'Claim 1888 daily!',
  'promotion.details.paly': 'PLAY NOW',
  'promotion.details.duration': '长期活动',
  'promotion.dailyCheckin.title': '每日签到',
  'promotion.dailyCheckin.viewMore': '查看更多',
  'promotion.dailyCheckin.day': 'day',
  'promotion.dailyCheckin.already': '已签到',
  'promotion.dailyCheckin.pending': '待签到',
  'promotion.dailyCheckin.active': '立即签到',
  'promotion.dailyCheckin.continuous': '已连续签到',
  'promotion.dailyCheckin.receivedAmount': '已领取',
  'promotion.dailyCheckin.rechargeAmount': '存款金额',
  'promotion.dailyCheckin.rulesTitle': '签到规则',
  'promotion.dailyCheckin.popup.success.primaryText': '签到成功',
  'promotion.dailyCheckin.popup.success.subText': '恭喜您，获得奖金{bonus}VNĐ',
  'promotion.dailyCheckin.popup.success.button': '知道了',
  'promotion.dailyCheckin.popup.warning.primaryText': '不符合条件',
  'promotion.dailyCheckin.popup.warning.subText': '您尚未满足当日充值条件。 请存入必要的金额',
  'promotion.dailyCheckin.popup.warning.button': '立即充值',

  // 错误页 维护、访问限制等
  'errorPage.maintenance': '平台维护',
  'errorPage.maintenance.timeLabel': '维护时间：',
  'errorPage.maintenance.tips': '给您造成的不便，敬请谅解。<br/>如您有任何疑问，请联 系我们的在线客服。',
  'errorPage.maintenance.hasEnded': '维护已结束',
  'errorPage.maintenance.backHome': '返回首页',

  'settings.home': 'settings',
  'vip.detail.title': 'VIP详情',
  'vip.detail.enjoy': 'VIP尊享',
  'vip.detail.upgrades': '升级礼金',
  'vip.detail.birthday': '生日礼金',
  'vip.detail.tips': '礼金领取成功',
  'vip.detail.DrawDown': '立即领取',
  'vip.detail.firstHalfMonth': '上半月红包',
  'vip.detail.secondHalfMonth': '下半月红包',
  'vip.detail.monthlyRedPacket': '每月红包',
  'vip.detail.holidayGift': '佳节礼品',
  'vip.detail.exclusiveEvent': '专属活动',
  'vip.detail.fastMoneyOut': '极速出款',
  'vip.detail.currentLevel': '当前等级',
  'vip.detail.maxLevel': '已满级',
  'vip.detail.upgraded': '待升级',
  'vip.detail.Unlock': '立即解锁',
  'vip.detail.await': '升级解锁',
  'vip.detail.currentDeposit': 'VIP详情',
  'vip.detail.activeBet': '有效投注',
  'vip.detail.theNext': '升至下一级',
  'vip.detail.accumulatedDeposit': '累计存款(元)',
  'vip.detail.flowRequirement': '流水要求(元)',
  'vip.detail.gradingWater90': '保级流水90天(元)',
  'vip.detail.vipLevel': 'VIP等级',
  'vip.detail.depositRequirement': '存款要求',
  'vip.detail.rights': 'VIP权益',
  'vip.detail.text1': '发放的福利可至',
  'vip.detail.welfareCenter': '福利中心',
  'vip.detail.text2': '兑换',
  'vip.detail.upgradeExp': '升值经验值',
  'vip.detail.maintainExp': '保级经验值',
  'vip.detail.ruleDescription': 'VIP规则说明',
  'vip.detail.bootonAllRight': '英凰体育保留对获得的修改，停止及最终解释权。',
  'vip.detail.text3': '晋升标准',
  'vip.detail.text4': 'VIP会员的累计存款及累计流水（投注结算后系统认定有效投注额）达到相应级别的要求，既可晋级相应VIP等级，会员VIP资料统计时间从VIP系统上线当日00:00:00开始统计。',
  'vip.detail.text5': '晋升规则',
  'vip.detail.text6': 'VIP等级达到相应的要求可每天晋升一级，但VIP等级不可越级晋升。',
  'vip.detail.text7': '保级要求',
  'vip.detail.text8': 'VIP会员在“VIP等级变更”后，90天内投注需完成相应等级的流水要求对方可继续保级90天;如果在此期间完成晋升，保级要求重新按照当前等级计算。',
  'vip.detail.text9': '降级标准',
  'vip.detail.text10': '如果VIP会员在90天内没有完成相应的保级流水要求，系统会自动降级一个等级，相应的优惠也会随之调整至降级后的等级。',
  'vip.detail.text11': '晋级福利',
  'vip.detail.text12': '晋级福利在会员达到该VIP会员级别后系统自动派发，每个级别的晋级福利每位VIP会员仅能获得1次 (晋级福利1杯流水即可提款)',
  'vip.detail.text13': '每月红包',
  'vip.detail.text14': 'VIP会员在上个月有过至少1次的成功存款，即可在每个月1号获得上个月相应等级得每月红包彩金 (每月红包彩金1倍流水即可提款)',
  'vip.detail.text15': '生日福利',
  'vip.detail.text16':
    '礼金将于会员生日当天由系统自动发放至VIP中心且30天内领取使用，过期作废不予补发。礼金金额以生日当天的VIP等级为准，每年仅可领取一次，如会员未设置生日则视为自动放弃（生日礼金仅需3倍流水即可提款）。',
  'vip.detail.remainingDays': '保级晋级剩余天数',
  'profile.menu.vip0': '完成首存即为VIP1',
  'vip.detail.days': '天',

  'vip.detail.stat0': '未达标',
  'vip.detail.stat2': '立即参加',
  'vip.detail.stat4': '领取中',
  'vip.detail.stat6': '已领取',
  'vip.detail.stat12': '已失效',
  'vip.home.birthday': '生日礼金',
  'vip.home.upgrade': '晋级奖金',
  'vip.home.midMonth': '月中红包',
  'vip.home.endMonth': '月底红包',
  'vip.home.to': ' to ',

  'vip.detail.notUpToStandard': '未达标',
  'vip.detail.joinAtOnce': '立即参加',
  'vip.detail.claiming': '领取中',
  'vip.detail.claimed': '已领取',
  'vip.detail.becameInvalid': '已失效',
  'vip.detail.has': '有',
  'vip.detail.hasnot': '无',
  'vip.detail.claimedOther': '已享有',
  'vip.detail.tablecontent': 'VIP豪礼拿不停，升级VIP另有晋级礼金、每月红包、佳节礼品、专属活动等丰厚礼金等着您',

  'settings.home.hello': '你好',
  'settings.home.register': '会员注册于 ',
  'settings.home.balance': '余额',
  'settings.home.transaction': '交易',
  'settings.home.vip': 'VIP',
  'settings.home.activity': '活动',
  'settings.home.address': '收款地址',
  'settings.home.history': '历史',
  'settings.home.username': '用户名',
  'settings.home.phoneNumber': '手机号码',
  'settings.home.registerDate': '注册时间',
  'settings.home.password': '密码',
  'settings.home.aboutUs': '关于我们',
  'settings.home.helpCenter': '帮助中心',
  'settings.home.level': '等级',
  'settings.home.experienceLevel': '经验值 ',
  'settings.home.details': 'VIP详情',
  'settings.home.more': '查看更多',
  'settings.home.special': 'VIP权益',

  'settings.home.confirmPassword': '确认新密码',
  'settings.home.oldPassword': '旧密码',
  'settings.home.mail': '邮箱',
  'settings.home.welcomeBonus': '100% 迎新奖励',
  'settings.home.betNow': '立即投注',

  'settings.home.cashOutAmount': '提现金额',
  'settings.home.requestAmount': '充值金额',
  'settings.home.cashInAmount': '到账金额',
  'settings.home.cashInAmountStr': '到账金额',
  'settings.home.cashInMethod': '充值方式',
  'settings.home.cashInAt': '转入 ',
  'settings.home.promotionAt': '到账 ',
  'settings.home.inData': '转入时间',
  'settings.home.outData': '转出时间',
  'settings.home.verify': '提现短信验证',

  'settings.home.help': 'Help',
  'settings.home.birthday': '生日',
  'settings.home.unlock': 'Unlock now',
  'settings.home.viewMore': 'View more',
  'settings.home.cashIn': 'Cash In',
  'settings.home.cashOut': 'Cash OUT',
  'settings.home.noMore': '没有更多了',
  'settings.home.noCotent': 'no content display here',
  helpText,
  pages
}
