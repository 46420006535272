import { createApp } from 'vue'
import App from './App.vue'
import 'vant/es/toast/style'
import router from './router'

import { setupStore } from '@/store'

import 'vant/lib/index.css'
import 'amfe-flexible'
import '@/styles/style.scss'
import I18n from '@/locale'
import directives from './directive/index'
import Vue3Lottie from 'vue3-lottie'

import { Lazyload } from 'vant'

import { createChatWoot } from '@productdevbook/chatwoot/vue'
import { ChatWootBaseUrl, ChatWootWebToken } from './utils/constants'
import { deviceInfo } from '@/utils/native'
import * as Sentry from '@sentry/vue'

import InlineSvg from 'vue-inline-svg'
import { checkEndpoints } from '@hpoker/sdk/dist/utils/domain'
import { localCache } from '@hpoker/sdk/dist/utils/cache'
// import { localCache } from '@hpoker/sdk/dist/utils/cache'

const { VITE_APP_ENV, VITE_SENTRY_DSN } = import.meta.env
VITE_APP_ENV === 'production' && checkEndpoints()

const chatwoot = createChatWoot({
  init: {
    websiteToken: ChatWootWebToken,
    baseUrl: localCache.getCache('endpoint') ? `https://chat.${localCache.getCache('endpoint')}` : ChatWootBaseUrl
    // baseUrl: ChatWootBaseUrl
  },
  settings: {
    locale: 'vi',
    position: 'left',
    launcherTitle: 'AB77',
    darkMode: 'auto',
    hideMessageBubble: true,
    showPopoutButton: false
  },
  partytown: false
})

VITE_APP_ENV === 'production' &&
  Sentry.init({
    dsn: VITE_SENTRY_DSN
  })

if (VITE_APP_ENV === 'test' && import.meta.env.MODE !== 'development') {
  // 如果document.cookie中没有fe-mr=app-debug
  if (deviceInfo.isNative && !document.cookie.includes('fe-mr=app-debug')) {
    // 内嵌app环境使用专用的fe-mr
    document.cookie = 'fe-mr=app-debug;path=/;'
    setTimeout(() => {
      window.location.reload()
    }, 500)
  } else {
    import('@hpoker/sdk').then((module) => {
      module.debug.addDebugButton()
    })
  }
}

const app = createApp(App)
app.use(router).use(I18n).use(setupStore).use(Lazyload)
app.use(directives)
app.use(Vue3Lottie)
app.use(chatwoot)
// 全局注册inline-svg组件
app.component('InlineSvg', InlineSvg)
app.mount('#app')
