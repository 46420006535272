import { service } from '@/service'
import {
  CommonApi,
  ICommonApiRequest,
  ICommonApiResponse,
  IDomainCheckRequest,
  IDomainCheckResponse,
  IGetAgentCodeRequest,
  IGetAgentCodeResponse,
  IPlatformMaintainResponse,
  IRecordAgentCodeRequest,
  IReportDataConfigRequest,
  IReportDataConfigResponse
} from './types'

export function getDeviceId(data: ICommonApiRequest) {
  return service.post<ICommonApiResponse>({
    url: CommonApi.GetDeviceId,
    data,
    showErrorToast: false
  })
}

export function getPlatformMaintain() {
  return service.get<IPlatformMaintainResponse>({
    url: CommonApi.GetPlatformMaintain
  })
}

export function getDomainCheck(data: IDomainCheckRequest) {
  return service.post<IDomainCheckResponse>({
    url: CommonApi.DomainCheck,
    data: data
  })
}

export function getReportDataConfig(params?: IReportDataConfigRequest) {
  return service.get<IReportDataConfigResponse[]>({
    url: CommonApi.GetReportDataConfig,
    params
  })
}

export function recordAgentCode(data: IRecordAgentCodeRequest) {
  return service.post({
    url: CommonApi.RecordAgentCode,
    data
  })
}

export function getAgentCode(params?: IGetAgentCodeRequest) {
  return service.get<IGetAgentCodeResponse>({
    url: CommonApi.GetAgentCode,
    params
  })
}
