<script setup lang="ts">
import { computed } from 'vue'
import useNoticeStore from '@/store/message/notice'
import useCustomerStore from '@/store/customer'
import { onUnmounted } from 'vue'
import { includes } from 'lodash-es'
import { useChatWoot } from '@productdevbook/chatwoot/vue'

const customerStore = useCustomerStore()
let customerTimer: string | number | NodeJS.Timeout | undefined = undefined

const handleService = async () => {
  await customerStore.goToCustomerService()
  customerTimer = setTimeout(() => {
    const { popoutChatWindow } = useChatWoot()
    const element = document.getElementsByClassName('woot-widget-holder').item(0)
    const elementClassName = element?.className
    const isHidding = includes(elementClassName, 'woot--hide')
    // 如果客服是隐藏状态，则打开新窗口
    if (isHidding) {
      popoutChatWindow()
    }
  }, 400)
}

// 跑马灯公告
const noticeStore = useNoticeStore()
const { getHomeNotice, hideHomeNotice } = noticeStore

getHomeNotice()

onUnmounted(() => {
  clearTimeout(customerTimer)
})

const openPopup = (index: number) => {
  const noticeData = noticeStore.homeNotice.map((v) => ({
    title: v.title,
    message: v.message,
    createTime: v.createTime
  }))
  noticeStore.applyNoticePopup(noticeData, index)
}
</script>

<template>
  <div class="container">
    <div class="noticeBar-warp">
      <van-notice-bar color="#fafafa" background="transparent">
        <template #left-icon>
          <img class="left-icon" src="@/assets/components/icon_speaker.svg" alt="" />
        </template>
        <div class="notice-content">
          <span v-for="(item, index) in noticeStore.homeNotice" @click="openPopup(index)" class="notice-item">
            {{ item.title }}
          </span>
        </div>
      </van-notice-bar>
    </div>
    <!-- <div class="customer" @click="handleService">
      <img src="@/assets/components/small_icon.svg" alt="" />
    </div> -->
  </div>
</template>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 34px;
  display: flex;
  font-size: 12px;
}
.noticeBar-warp {
  flex: 1;
  background-color: #27272a;
  .notice-item {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.customer {
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #fff8eb 0%, #f0d39b 100%);
  border-radius: 12px 0px 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-icon {
  margin-right: 10px;
}
</style>
