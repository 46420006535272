import scorllShowElement from './scorllShowElement'
import { Directive, App } from 'vue'

const directives: { [key: string]: Directive } = {
  scorllShowElement
}

export default {
  install(app: App) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key])
    })
  }
}
