<script setup lang="ts">
import { useRoute } from 'vue-router'

interface Tab {
  id?: number
  name: string
  path: string
  parent?: string //用于tabs path属性和当前路径不匹配也要显示高亮的情况,如果有这种情况，需要在路由meta中添加parent属性
  isAnimate?: boolean
}
const route = useRoute()
defineProps({
  data: {
    type: Array as () => Tab[],
    required: false,
    default: () => []
  },
  isAnimate: {
    type: Boolean,
    default: false
  }
})

//判断是否是当前路由，如果是则添加active类名
const isActive = (val: any) => {
  //正常情况下，只有一个父级，所以只需要判断父级是否相等即可
  if (val.path === route.path) {
    return true
  }
  //如果有多个父级，需要判断父级是否相等
  if (val.parent && val.parent === route.meta.parent) {
    return true
  }
  return false
}
</script>
<template>
  <router-link :to="val.path" replace v-for="val in data" class="tabsItem" :class="{ active: isActive(val) }" :key="val.path">
    <span class="mas">{{ val.name }}</span>
    <i class="anima" v-if="isAnimate"></i>
  </router-link>
</template>

<style scoped lang="scss">
@mixin anima($bcolor, $url, $x1, $y1) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
}
.tabsItem {
  display: block;
  overflow: hidden;
  flex: 1;
  height: 30px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.3s linear;
  position: relative;
}
.mas {
  color: #808089;
  overflow: hidden;
  position: absolute;
  inset: 0;
  height: 100%;
}

.active {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 12px 24px 0px rgba(255, 255, 255, 0.5);
  .mas {
    color: #282828;
  }
  .anima {
    width: 100%;
    height: 100%;
    display: block;
    @include anima(#282828, '@/assets/animation/nature-sprite.png', 2300%, 100%);
    cursor: pointer;
    -webkit-animation: ani2 0.8s steps(22) forwards;
    animation: ani2 0.8s steps(22) forwards;
    -webkit-animation: ani 0.8s steps(22) forwards;
    animation: ani 0.8s steps(22) forwards;
  }
}
@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}
</style>
