<script setup lang="ts">
import dayjs from 'dayjs'
import BaseArrowButton from '@/components/BaseArrowButton.vue'
import useNoticeStore from '@/store/message/notice'
import { computed, ref, Ref } from 'vue'
import useUserStore from '@/store/user'

const noticeStore = useNoticeStore()
const userStore = useUserStore()
const activeNotice = computed(() => {
  const id = noticeStore.noticePopupList[noticeStore.noticePopupActiveIndex]?.id
  if (!noticeStore.noticeIds.find((item) => item === id) && userStore.isLogin) {
    noticeStore.hasReads(id, 0)
    noticeStore.noticeIds.push(id)
  }
  return noticeStore.noticePopupList[noticeStore.noticePopupActiveIndex] || null
})

const onChange = (direction: 'left' | 'right') => {
  const { noticePopupActiveIndex, noticePopupList } = noticeStore
  const step = direction === 'left' ? -1 : 1
  let newIndex = noticePopupActiveIndex + step

  if (newIndex < 0) {
    newIndex = 0
  } else if (newIndex >= noticePopupList.length) {
    newIndex = noticePopupList.length - 1
  }

  // noticeStore.hasReads(noticePopupList[newIndex].id, 0)
  noticeStore.noticePopupActiveIndex = newIndex
}
</script>

<template>
  <van-popup v-model:show="noticeStore.showNoticePopup" teleport="#app" position="bottom" class="notice-popup">
    <div class="top">
      ><span>{{ dayjs(activeNotice?.createTime).format('YY.MM.DD HH:mm') }} </span>
    </div>
    <div class="title">{{ activeNotice?.title }}</div>
    <div class="content" v-html="activeNotice?.message"></div>
    <div class="arrows">
      <span v-if="noticeStore.noticePopupActiveIndex > 0" class="left" @click="onChange('left')"></span>
      <span v-if="noticeStore.noticePopupActiveIndex !== noticeStore.noticePopupList.length - 1" class="right" @click="onChange('right')"></span>
    </div>
    <div class="bottom">
      <BaseArrowButton type="down" @click="noticeStore.showNoticePopup = false" />
    </div>
  </van-popup>
</template>

<style lang="scss" scoped>
.notice-popup {
  bottom: 40px;
  margin: 0 8px;
  padding: 12px 12px;
  width: calc(100% - 16px);
  min-height: 240px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #eaeaf0;
  background: #ffffff;
  color: #27272a;
  font-size: 12px;
  line-height: 20px;
  z-index: 20001 !important;
  .top {
    font-weight: 500;
    text-align: right;
    line-height: 16px;
    span {
      margin-left: 8px;
      opacity: 0.45;
    }
  }
  .title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .content {
    margin-bottom: 12px;
  }
  .arrows {
    margin: auto 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      width: 24px;
      height: 24px;
      background: url('@/assets/components/notice_popup_arrow.svg') no-repeat center center / 100% 100%;
      &.right {
        margin-left: auto;
        transform: rotate(180deg);
      }
    }
  }
  .bottom {
    margin: 0 -4px;
  }
}
</style>
