// 游戏相关
export enum gameAPI {
  getGameInfos = '/client/cooperate/gameInfo/getGameInfos',
  getGameMaintain = '/game-maintain/info',
  gameLogin = '/cooperate/gameCenter/login',
  guestLogin = '/client/cooperate/direct/guestLogin',
  getGameBalance = '/cooperate/gameCenter/getBalance',
  syncGameBalance = '/cooperate/gameCenter/syncBalance',
  syncCenterBalance = '/cooperate/gameCenter/syncCenterBalance',
  getChildGameInfos = '/client/cooperate/gameInfo/getChildGameInfos',
  getLive = '/client/cooperate/direct/liveMatch'
}

export interface IGameInfosResponse {
  code?: string
  gameInfos?: IGameInfo[]
  id: number
  name: string
  sort?: number
  status?: number
  [property: string]: any
}

export interface IGameInfo {
  /**
   * 渠道code
   */
  channelGameCode?: string
  /**
   * 渠道id
   */
  channelId?: number
  code?: string
  gameMtainInfo?: {
    /**
     * 设备类型，0:PC,1:H5,2:ANDROID,3:IOS
     */
    deviceType: number[]
    /**
     * 禁用服务，1:入口
     */
    disableService: number
    /**
     * 结束时间戳,毫秒
     */
    endTime: number
    /**
     * 开始时间戳,毫秒
     */
    startTime: number
    /**
     * 状态 1 维护中
     */
    status: number
    [property: string]: any
  }
  /**
   * 游戏类型：1体育，2电竞，3彩票，4真人，5捕鱼，6电子，7棋牌，8金融
   */
  gameTypeId?: number
  id: number
  /**
   * 角标信息 * 1:敬请期待 * 2:新上线 * 3:推荐 * 0:无
   */
  activeIndexInfo?: IIndexInfo
  indexInfos?: IIndexInfo[]
  logoList?: IGameLogo[]
  name?: string
  sort?: number
  /**
   * 0:正常 1:下架
   */
  status?: number
  /**
   * 1:转账钱包 2:共享钱包
   */
  walletType?: number
  imgUrl?: string
  /**
   * 是否有子游戏
   */
  hasChild: boolean
  [property: string]: any
}

export interface IIndexInfo {
  /**
   * 0:PC,1:H5,2:ANDROID,3:IOS
   */
  deviceType: number
  /**
   * 所属游戏
   */
  gameId?: number
  id?: number
  /**
   * 角标信息 * 1:敬请期待 * 2:新上线 * 3:推荐 * 0:无
   */
  indexInfo: number
  /**
   * 跳转url
   */
  linkUrl?: string
  [property: string]: any
}

export interface IGameLogo {
  /**logo
   * 设备类型 PC:0 H5:1 ANDROID:2 IOS:3 PAD:4;
   */
  deviceType?: number
  id?: number
  /**
   * 语言 1:英文 2:越语 3:中文
   */
  language?: number
  logoUrl?: string
  /**
   * 1大 2小 3缩
   */
  type?: number
  [property: string]: any
}

export interface IGameLoginRequest {
  /**
   * 三方游戏code
   */
  channelGameCode?: string
  /**
   * 渠道id
   */
  channelId: number
  /**
   * 游戏id
   */
  gameId: number
  /**
   * 游戏类型id
   */
  gameType: number
  [property: string]: any
}

export interface IGameLoginResponse {
  apiServerAddress: string
  h5Address: string
  pcAddress: string
  pushServerAddress: string
  loginUrl: string
  token: string
  errCode: number // 0 转账处理中 1 转账成功  2 转账失败
  [property: string]: any
}

export interface IGuestLoginResponse {
  apiServerAddress: string
  h5Address: string
  pcAddress: string
  pushServerAddress: string
  [property: string]: any
}

export interface IGetGameBalanceRequest {
  channelId: number
  gameId: number
  gameType: number
  [property: string]: any
}

export interface IGetGameBalanceResponse {
  balance?: number
  currencyId?: number
  [property: string]: any
}

export interface ISyncGameBalanceRequest {
  /**
   * 渠道id
   */
  channelId: number
  /**
   * 游戏id
   */
  gameId: number
  /**
   * 游戏类型id
   */
  gameType: number
  [property: string]: any
}

export interface ISyncGameBalanceResponse {
  balance?: number
  currencyId?: number
  status?: number
  [property: string]: any
}

export interface IChildGameInfosRequest {
  current: number
  gameCategoryId?: number
  parentGameId: number
  size: number
  /**
   * 0正常 1冻结
   */
  status?: number
  gameName?: string
  [property: string]: any
}

export interface ISubGameTab {
  /**
   * code
   */
  code?: string
  id: number
  /**
   * 名称
   */
  name: string
  /**
   * 排序
   */
  sort?: number
  [property: string]: any
}

export interface IChildGameInfosResponse {
  current?: number
  pages?: number
  records: IGameInfo[]
  gameCategories?: ISubGameTab[]
  size?: number
  total?: number
  [property: string]: any
}

interface TeamInfo {
  /**
   * 队伍id
   */
  teamId: string
  /**
   * 队伍名
   */
  teamName: string
  /**
   * 队伍图标
   */
  teamPicUrl: string
}

export interface IGetLiveResponse {
  /**
   * 直播中赛事
   */
  liveMatch: {
    /**
     * 客队信息
     */
    awayTeam: TeamInfo
    /**
     * 主队信息
     */
    homeTeam: TeamInfo
    /**
     * 联赛id
     */
    leagueId: string
    /**
     * 联赛等级
     */
    leagueLevel: number
    /**
     * 联赛名
     */
    leagueName: string
    /**
     * 联赛图标
     */
    leaguePicUrl: string
    /**
     * 赛事动画地址
     */
    matchAnimationUrl?: string
    /**
     * 赛事开始时间
     */
    matchBeginTime: string
    /**
     * 赛事id
     */
    matchId: string
    /**
     * 赛事地址
     */
    matchVideo: {
      flvSD: string
      m3u8SD: string
      web: string
    }
    /**
     * 赛事直播地址
     */
    matchVideoUrl?: string
    /**
     * 体育code
     */
    sportCode: string
    /**
     * 体育名
     */
    sportName: string
  }[]
  /**
   * 未开始赛事
   */
  notStartedMatch: {
    awayTeam: TeamInfo
    homeTeam: TeamInfo
    leagueId: string
    leagueLevel: number
    leagueName: string
    leaguePicUrl: string
    matchAnimationUrl?: string
    matchBeginTime?: string
    matchId: string
    matchUrl: string
    sportCode: string
    sportName: string
  }[]
}
