import HttpRequest from '@/utils/request'
import { timeout } from '@/config/service/config'

export const service = new HttpRequest({
  timeout,
  interceptors: {
    requestInterceptor: (config) => {
      return config
    },
    responseInterceptor: (res) => {
      return res
    }
  }
})
