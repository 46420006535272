import { service } from '@/service'
import {
  FbSportsAPI,
  IGetMatchListReq,
  IGetMatchListRes,
  IStatisticalRes,
  IGetBatchBetMatchMarketReq,
  IGetBatchBetMatchMarketRes,
  IUserBalanceRes,
  ISinglePassReq,
  ISinglePassItem,
  IOrderStatusItem,
  ILangStatic
} from './types'
import useFbSportsStore from '@/store/fbSports'

// FB-接口公共参数
// const commonParams = {
//   languageType: fbSportsStore.lang
// }

// 查询用户余额
export function getUserBalance() {
  const fbSportsStore = useFbSportsStore()
  return service.post<IUserBalanceRes>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.GetUserBalance,
    data: { languageType: fbSportsStore.lang }
  })
}

// 赛事统计、按运动、分类类型统计可投注的赛事个数
export function getStatistical() {
  const fbSportsStore = useFbSportsStore()
  return service.post<IStatisticalRes>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.Statistical,
    data: { languageType: fbSportsStore.lang }
  })
}

// 静态多语言数据
export function getStaticList() {
  const fbSportsStore = useFbSportsStore()
  return service.post<ILangStatic>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.StaticList,
    data: { languageType: fbSportsStore.lang }
  })
}

// 获取赛事列表
export function getMatchList(data: IGetMatchListReq) {
  const fbSportsStore = useFbSportsStore()
  return service.post<IGetMatchListRes>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.MatchList,
    data: { ...data, languageType: fbSportsStore.lang }
  })
}

// 投注前查询指定玩法赔率
export function getBatchBetMatchMarketOfJumpLine(data: IGetBatchBetMatchMarketReq) {
  const fbSportsStore = useFbSportsStore()
  return service.post<IGetBatchBetMatchMarketRes>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.BatchBetMatchMarketOfJumpLine,
    data: { ...data, languageType: fbSportsStore.lang }
  })
}

// 单关投注接口，可批量
export function singlePass(data: ISinglePassReq) {
  const fbSportsStore = useFbSportsStore()
  return service.post<ISinglePassItem[]>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.SinglePass,
    data: { ...data, languageType: fbSportsStore.lang }
  })
}

// 批量获取投注订单状态
export function getStakeOrderStatus(orderIds: string[]) {
  const fbSportsStore = useFbSportsStore()
  return service.post<IOrderStatusItem[]>({
    baseURL: fbSportsStore.baseUrl,
    url: FbSportsAPI.GetStakeOrderStatus,
    data: { orderIds, languageType: fbSportsStore.lang }
  })
}
