import mitt from 'mitt'

export enum Events {
  GET_NATIVE_USERINFO_SUCCESS = 'getNativeUserInfoSuccess',
  GET_NATIVE_USERINFO_ERROR = 'getNativeUserInfoError'
}

const emitter = mitt()

export default emitter
