import { TEventName } from './index'

export type TiktokPixelEvent = 'CompleteRegistration' | 'PlaceAnOrder' | 'registerSubmit' | 'firstOpen'

const agentEventNameMap: Record<string, string> = {
  registerSuccess: 'CompleteRegistration',
  firstDepositSubmit: 'PlaceAnOrder',
  depositSubmit: 'PlaceAnOrder',
  registerSubmit: 'registerSubmit',
  firstOpen: 'firstOpen'
}

function recordEvent(originEventName: TEventName, originEventValue?: any) {
  const { vipAgents = [] } = window
  const hasTiktok = vipAgents.some((v) => v.platform === 'Tiktok')
  if (!hasTiktok) return

  const eventName = agentEventNameMap[originEventName]
  if (!eventName) return

  try {
    let eventValue
    if (eventName === 'PlaceAnOrder') {
      eventValue = {
        value: originEventValue?.amount || 0,
        currency: 'VND'
      }
    }
    window?.ttq?.track(eventName as TiktokPixelEvent, eventValue)
  } catch (e) {}
}

export default {
  name: 'tiktokPixel',
  recordEvent
}
