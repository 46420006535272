<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="banner-contianer">
    <img class="logo" src="@/assets/components/bannerLogo.png" alt="" />
    <span class="text">{{ t('login.welcome') }}</span>
  </div>
</template>

<style scoped lang="scss">
.banner-contianer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  /* padding-top: 16px; */
  .logo {
    width: 68.64px;
    height: 32px;
  }
  .text {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: right;
    color: #ffffff;
  }
}
</style>
