// 优惠活动相关

export enum PromotionAPI {
  GetBanner = '/client/banner/getBanner',
  GetDepositActivityConfig = '/activity/config/get/recharge/activityConfig',
  GetDepositActivityForUser = '/activity/get/rechargeActivity/userId',
  SubmitDepositActivity = '/activity/join/rechargeActivity',
  GetShareJackpotRules = '/activity/config/get/shareJackpotRules',
  GetRebateActivityConfig = '/activity/config/get/rebate/activityConfig',
  GetBetBonusActivityConfig = '/activity/config/get/bet/bonusRules',
  GetBetBonusActivity = '/activity/get/betGetBonusActivity/info',
  SubmitBetBonusActivity = '/activity/claim/betGetBonus/bonusActivity',
  GetSlotBetBonusActivityConfig = '/activity/config/get/eReliefRules',
  GetSlotBetBonusActivity = '/activity/get/eReliefActivity/info',
  SubmitSlotBetBonusActivity = '/activity/claim/eRelief/bonusActivity',
  GetDepositActivityCopywriting = '/activity/config/get/recharge/activityRule',
  GetDailyCheckInConfig = '/activity/config/get/dailyCheckIn/activityConfig',
  GetDailyCheckInByUser = '/activity/getDailyCheckInByUserId',
  ApplyDailyCheckIn = '/activity/applyDailyCheckIn',
  GetDailyCheckInRule = '/activity/config/get/dailyCheckIn/activityRule',
  GetReferralRally = '/activity/referralRally/getReferralRally',
  GetReferralRallyRule = '/activity/referralRally/getReferralRallyRule',
  GetReferralRallyConfig = '/activity/referralRally/getReferralRallyConfig',
  ReceiveReferralRallyReward = '/activity/referralRally/receiveReferralRallyReward',
  GetReferralRallyDetail = '/activity/referralRally/getReferralRallyDetail',
  GetEuropeanPreheatInfo = '/activity/config/getEuropeanCupPreheatActivityInfo',
  ApplyEuropeanPreheat = '/activity/participateEuropeanPreheat',
  GetEuroPreheatRewards = '/activity/getEuroPreheatRewards',
  GetEuropeanCupPreheatBasicInfo = '/activity/config/get/europeanCupPreheatBasicInfo',
  GetEuroCupPenaltyKickRule = '/activity/selectActivityRule',
  GetEuroCupPenaltyKickConfig = '/activity/penalty/getPenaltyActivityConfig',
  GetEuroCupPenaltyKickDetail = '/activity/penalty/getPenaltyActivity',
  SubmitPenaltyKick = '/activity/penalty/userPenalty',
  GetEuroCupPenaltyKickReward = '/activity/penalty/receiveReward',
  GetPromotionList = '/client/activity/getBannerList',
  GetGuessingActivityConfig = '/activity/guess/getGuessingActivityConfig', //  竞猜 - 查询竞猜活动配置
  SelectActivityRule = '/activity/selectActivityRule', //  竞猜 - 获取指定活动规则
  GetGuessActivityBetDetail = '/activity/guess/getGuessActivityBetDetail', //  竞猜 - 查询竞猜活动详情
  VoteGuess = '/activity/guess/voteGuess', //  竞猜 - 参与竞猜-投票指定队伍
  GetGuessActivityAll = '/activity/guess/getGuessActivityAll', //  竞猜 - 查询所有球队的投票信息
  GetMidAutumnActivityConfig = '/activity/midAutumn/getMidAutumnConfig', // 中秋活动配置
  GetMidAutumnActivityList = '/activity/midAutumn/get/midAutumn/pageInfo', //查询中秋活动分页数据
  GetMidAutumnActivityByUser = '/activity/midAutumn/getMidAutumnRankByUserId', //查询当前用户-中秋活动数据
  GetSportsBettingConfig = '/activity/sportLeague/getSportLeagueBonus', //体育投注-五大联赛 获取活动配置
  GetSportsBettingRule = '/activity/selectActivityRule', //体育投注-五大联赛 活动规则
  ApplySportsBetting = '/activity/sportLeague/bonusSportLeague', //体育投注-五大联赛 参与活动&选择队伍
  GetBalanceInterestConfig = '/activity/leftoverDeposit/getConfig', //余额宝-获取活动配置
  GetBalanceInterestRule = '/activity/selectActivityRule', //余额宝-活动规则
  ApplyBalanceInterest = '/activity/leftoverDeposit/joinActivity', //余额宝-参与活动
  GetLuckyOrderConfig = '/activity/luckyOrder/getConfig', //幸运单号活动配置
  GetLuckyOrderRewards = '/activity/luckyOrder/searchRewards', //幸运单号活动规则
  ApplyLuckyOrder = '/activity/luckyOrder/receiveRewards', //幸运单号领取奖励
  GetBetCompensationConfig = '/activity/sportFirstOrder/getConfig', //首单包赔活动配置
  ApplyBetCompensation = '/activity/sportFirstOrder/joinActivity' //参与首单包赔活动
}

export interface IBanner {
  /**
   * url
   */
  bannerUrl: string
  /**
   * 描述
   */
  depict: string
  /**
   * 跳转链接
   */
  jumpLink: string
  /**
   * 排序
   */
  sort: number

  [property: string]: any
}

export interface IDepositActivityConfig {
  /**
   * 首充
   */
  rechargeFirst: RechargeConfig
  /**
   * 次充
   */
  rechargeSecond: RechargeConfig

  [property: string]: any
}

export interface RechargeConfig {
  /**
   * 投注倍数
   */
  betFactor: number
  /**
   * 奖金比例
   */
  bonusRatio: number
  /**
   * 最大金额
   */
  maxAmountLimit: number
  /**
   * 最小金额
   */
  minAmountLimit: number

  [property: string]: any
}

export interface IDepositActivityForUser {
  /**
   * 首充
   */
  rechargeFirst?: RechargeForUser
  /**
   * 次充
   */
  rechargeSecond?: RechargeForUser

  [property: string]: any
}

export interface RechargeForUser {
  /**
   * 活动ID
   */
  activityId: number
  /**
   * 流水
   */
  amountBet: number
  /**
   * 奖励金额
   */
  bonusAmount: number
  /**
   * 奖金比例
   */
  bonusRatio: number
  /**
   * 充值金额
   */
  rechargeAmount: number
  /**
   * 状态， 0: 未达标，2：立即参加，4：领取中，6：已领取，12：失效，
   */
  status: number

  [property: string]: any
}

export interface ISubmitCommonActivityReq {
  /**
   * 活动ID ，前面接口返回的
   */
  activityId: number

  [property: string]: any
}

export interface IShareJackpotRule {
  /**
   * 总金额
   */
  amountBonus: number
  /**
   * 时间
   */
  dayTime: number
  /**
   * 人数
   */
  peopleNum: number

  [property: string]: any
}

export interface IRebateActivityConfig {
  /**
   * 游戏ID
   */
  gameId?: number
  /**
   * 游戏类型ID
   */
  gameType?: number
  /**
   * 游戏名字
   */
  gameName?: string
  /**
   * 返水限制
   */
  limitRebate?: number
  /**
   * 返水规则
   */
  rebateActivityRules?: RebateActivityRule[]
  /**
   * 备注
   */
  remark?: string

  [property: string]: any
}

export interface RebateActivityRule {
  /**
   * 最大
   */
  max?: number
  /**
   * 最小
   */
  min?: number
  /**
   * 返水比例
   */
  rebateRatio?: number

  [property: string]: any
}

export interface IBetBonusActivityRes {
  userBetGetBonusInfoVOS: UserBetGetBonusInfoVO[]
  userId: number
  /**
   * 游戏投注
   */
  validBetAmount: number

  [property: string]: any
}

export interface UserBetGetBonusInfoVO {
  /**
   * 奖励金额
   */
  amountReward: number
  /**
   * 有效投注
   */
  betAmountLimit: number
  /**
   * 活动ID
   */
  id?: number
  /**
   * 状态， 0: 未达标，2：立即参加，4：领取中，6：已领取，12：失效，
   */
  status?: number
  loading?: boolean

  [property: string]: any
}

export interface ISlotBetBonusActivityRes {
  /**
   * 负盈利
   */
  negativeProfit: number
  userEReliefInfoVOS: UserEReliefInfoVO[]
  /**
   * 用户ID
   */
  userId: number

  [property: string]: any
}

export interface UserEReliefInfoVO {
  /**
   * 救济金
   */
  amountRelief: number
  /**
   * 活动ID
   */
  id?: number
  /**
   * 负盈利
   */
  negativeProfitMin: number
  negativeProfitMax: number
  /**
   * 状态， 0: 未达标，2：立即参加，4：领取中，6：已领取，12：失效， 14: 进行中
   */
  status?: number
  loading?: boolean

  [property: string]: any
}

export interface IBetBonusActivityConfig {
  /**
   * 奖励金额
   */
  amountReward: number
  /**
   * 投注金额要求
   */
  betAmountLimit: number
  status?: number
  loading?: boolean

  [property: string]: any
}

export interface ISlotBetBonusActivityConfig {
  /**
   * 当日充值金额
   */
  dailyRecharge: number
  ereliefRules: IEReliefRule[]
  /**
   * 适用游戏
   */
  gameIds: number[]
  /**
   * 流水倍数
   */
  multiple: number
  /**
   * 用户层级
   */
  userLevel: number[]
  /**
   * 活动开始时间
   */
  startTime: number
  /**
   * 活动结束时间
   */
  endTime: number
}

export interface IEReliefRule {
  /**
   * 救济金
   */
  amountRelief: number
  negativeProfitMin: number
  negativeProfitMax: number
  status?: number
  loading?: boolean

  [property: string]: any
}

// 每日签到活动配置
export interface IDailyCheckInConfigRes {
  /**
   * 奖金
   */
  bonus: number[]
  /**
   * 流水倍数
   */
  multiple: number
  /**
   * 充值金额
   */
  rechargeAmount: number

  [property: string]: any
}

// 用户每日签到详情
export interface IDailyCheckInByUserRes {
  /**
   * 连续签到天数
   */
  continuous: number
  /**
   * 累计领取奖励
   */
  receivedAmount: number
  /**
   * 当日存款
   */
  rechargeAmount: number
  todayCheckIn: boolean

  [property: string]: any
}

// 每日签到活动规则
export interface IDailyCheckInRuleRes {
  /**
   * 语言
   */
  coin?: string
  /**
   * 规则说明
   */
  description?: string
  [property: string]: any
}

// 好友邀请信息-链接
export interface IReferralRally {
  /**
   * 邀请链接
   */
  referralLink: string
  /**
   * 邀请人数
   */
  validInviteCount: number
  /**
   * 总奖励
   */
  totalReward: number
  /**
   * 已领取
   */
  receivedReward: number
  /**
   * 待领取
   */
  waitReward: number
  /**
   * 是否有审核中的奖励
   */
  auditingReward?: boolean

  /**
   * 本周已产生奖励
   */
  currentReward?: number
  /**
   * 奖励过期时间
   */
  expireTime?: number

  /**
   * 可领取奖励id
   */
  rewardId?: number
}

// 好友邀请活动规则
export interface IReferralRallyRules {
  /**
   * 语言
   */
  coin?: string
  /**
   * 规则说明
   */
  description?: string

  [property: string]: any
}

// 用户邀请返回详情
export interface IReferralRallyDetail {
  id: number
  userId: number
  username: string
  referralLink: string
  inviteCount: number
  inviteList: IinviteInfo[]
}

// 获取好友邀请活动配置
export interface IReferralRallyConfig {
  levels: number[]
  startTime?: number
  endTime?: number
  showStartTime?: number
  showEndTime?: number
  validMember?: number
  rewardType?: number
  // rewards?: IRewards[]
  rewardMap: RewardMap
  multiple: number
  referralRecharge: number
}

export interface RewardMap {
  [key: string]: any
}

// 邀请奖励规则
export interface IRewards {
  number: number
  amount: number
}

// 用户邀请详情
export interface IinviteInfo {
  id: number
  userId: number
  username: string
  registerTime: string
  rechargeAmount: number
  betAmount: number
  isValid: number
}

export interface IEuropeanPreheatInfoRes {
  /**
   * 门槛金额类型，0:充值金额 1:投注金额
   */
  amountType: number
  /**
   * 奖励领取结束时间戳
   */
  awardEndTime: number
  /**
   * 门槛奖励列表
   */
  awardList: EuropeanPreheatAward[]
  /**
   * 奖励领取开始时间戳
   */
  awardStartTime: number
  /**
   * 是否满足参与条件
   */
  joinCondition: boolean
  /**
   * 是否已参与
   */
  joined: boolean
  /**
   * 活动参与结束时间戳
   */
  joinEndTime: number
  /**
   * 活动参与开始时间戳
   */
  joinStartTime: number
  /**
   * 最低门槛金额
   */
  minConditionAmount: number
  /**
   * 活动展示结束时间戳
   */
  showEndTime: number
  /**
   * 活动展示开始时间戳
   */
  showStartTime: number
  /**
   * 总的参与人数
   */
  totalCount: number
  /**
   * 支持的会员等级
   */
  userLevel: number[]
  /**
   * 活动规则
   */
  ruleDesc: string
  [property: string]: any
}

export interface EuropeanPreheatAward {
  /**
   * 已达标人数
   */
  arriveUserCount?: number
  /**
   * 奖励金额
   */
  awardAmount?: number
  /**
   * 门槛金额
   */
  conditionAmount?: number
  /**
   * 门槛人数
   */
  conditionUserCount?: number
  /**
   * 奖励id
   */
  id: number
  /**
   * 是否满足领取条件
   */
  matchCondition?: boolean
  /**
   * 是否领取过
   */
  received?: boolean
  [property: string]: any
}

export interface IGetEuroPreheatRewardsReq {
  rewardId: number
}

export interface IGetEuropeanCupPreheatBasicInfoResponse {
  /**
   * 奖励领取结束时间戳
   */
  awardEndTime: number
  /**
   * 奖励领取开始时间戳
   */
  awardStartTime: number
  /**
   * 活动参与结束时间戳
   */
  joinEndTime: number
  /**
   * 活动参与开始时间戳
   */
  joinStartTime: number
  /**
   * 活动展示结束时间戳
   */
  showEndTime: number
  /**
   * 活动展示开始时间戳
   */
  showStartTime: number
  /**
   * 支持的会员等级
   */
  userLevel: number[]
}

export interface IGetEuroCupPenaltyKickRuleReuqest {
  /**
   * 点球活动:14
   */
  activityType?: number
}
export interface IGetEuroCupPenaltyKickRuleResponse {
  /**
   * 活动类型
   */
  activityType?: number
  /**
   * 币种
   */
  coin?: string
  /**
   * 描述
   */
  description?: string
  /**
   * 编辑信息
   */
  operator?: {
    /**
     * 编辑者id
     */
    operatorId: number
    /**
     * 编辑人
     */
    operatorName: string
    /**
     * 更新时间
     */
    updateTime: number
  }
}

export interface IGetEuroCupPenaltyKickConfigReuqest {
  [k: string]: any
}
export interface IGetEuroCupPenaltyKickConfigResponse {
  /**
   * 条件，0:充值,1有效投注
   */
  condition: number
  /**
   * 条件金额
   */
  conditionAmount: number
  /**
   * 活动时间
   */
  endTime: number
  /**
   * 通关失败 奖励比例(1-100)
   */
  failRate: number
  /**
   * 会员等级
   */
  levels: number[]
  /**
   * 点球关卡配置
   */
  penaltyConfigs: {
    /**
     * 关卡
     */
    level?: number
    /**
     * 通过率(1-100)
     */
    passRate?: number
    /**
     * 奖励系数
     */
    rewardMultiple?: number
  }[]
  /**
   * 奖励配置
   */
  rewardConfigs: {
    /**
     * 门槛金额
     */
    limitAmount?: number
    /**
     * 奖励金额
     */
    rewardAmount?: number
  }[]
  /**
   * 同设备号校验
   */
  sameDeviceCheck: boolean
  /**
   * 同ip校验
   */
  sameIpCheck: boolean
  /**
   * 活动展示时间
   */
  showEndTime: number
  /**
   * 活动展示时间
   */
  showStartTime: number
  /**
   * 活动时间
   */
  startTime: number
}

export interface IGetEuroCupPenaltyKickDetailReuqest {
  [k: string]: any
}
export interface IGetEuroCupPenaltyKickDetailResponse {
  /**
   * 基础奖励
   */
  baseBonus: number
  /**
   * 奖励
   */
  bonus: number
  /**
   * 能否开启点球
   */
  canJoin: boolean
  /**
   * 条件，0:充值,1投注
   */
  condition: number
  /**
   * 条件金额
   */
  conditionAmount: number
  /**
   * 当前关卡
   */
  currentLevel: number
  /**
   * 已领取奖励
   */
  receivedBonus: number
}

export interface ISubmitPenaltyKickReuqest {
  /**
   * 点球类型(0:左球,1:右球)
   */
  penaltyType?: number
}
export interface ISubmitPenaltyKickResponse {
  /**
   * 基础奖金
   */
  baseBonus: number
  /**
   * 奖金
   */
  bonus: number
  /**
   * 当前关卡
   */
  currentLevel: number
  /**
   * 点球是否猜中
   */
  penaltyResult: boolean
  /**
   * 剩余关卡
   */
  remainLevel: number
}
export interface Irecords {
  id: number
  applicationSides: string[]
  applicationSide: string[]
  depict: string
  title: string
  subTitle: string
  sort: number
  startTime: number
  endTime: number
  userId: number
  userName: string
  bannerUrls: any[]
  status: number
  createTime: number
  updateTime: number
  banner?: string
  name?: string
  cornerLogo?: string
  cornerId?: number
}

// 活动列表
export interface IPromotionList {
  current: number
  size: number
  total: number
  totalType: number
  records: Irecords[]
  pages: number
}

export interface IGetEuropeanCupPreheatBasicInfoResponse {
  /**
   * 奖励领取结束时间戳
   */
  awardEndTime: number
  /**
   * 奖励领取开始时间戳
   */
  awardStartTime: number
  /**
   * 活动参与结束时间戳
   */
  joinEndTime: number
  /**
   * 活动参与开始时间戳
   */
  joinStartTime: number
  /**
   * 活动展示结束时间戳
   */
  showEndTime: number
  /**
   * 活动展示开始时间戳
   */
  showStartTime: number
  /**
   * 支持的会员等级
   */
  userLevel: number[]
}

export interface IGuessingActivityConfig {
  levels: number[] // 参与会员等级
  startTime: number // 活动起止时间
  endTime: number // 活动起止时间
  showStartTime: number // 活动展示时间
  showEndTime: number // 活动展示时间
  defaultLevel: number // 默认会员等级
  initialTicket: number // 初始票据数量
  condition: number // 规则 0:充值金额,1:投注金额
  conditionAmount: number // 规则门槛金额
  rewardTicket: number // 奖励票券张数
  dailyTicketLimit: number // 每日获得票券上限
  ticketAmount: number // 票券金额
  ticketExpireTime: number // 票券过期时间
  guessingSwitch: boolean // 竞猜开关
  sixteenTeams: number[] // 十六强
  sixteenStartTime: number // 十六强投票时间
  sixteenEndTime: number // 十六强投票时间
  eightTeams: number[] // 八强
  eightStartTime: number // 八强投票时间
  eightEndTime: number // 八强投票时间
  fourTeams: number[] // 四强
  fourStartTime: number // 四强投票时间
  fourEndTime: number // 四强投票时间
  teamInfos: TeamInfo[] // 队伍信息
  /**
   * 八强投票开关
   */
  eightSwitch: boolean
  /**
   * 四强投票开关
   */
  fourSwitch: boolean
  /**
   * 十六强投票开关
   */
  sixteenSwitch: boolean
  /**
   * 冠军竞猜时间
   */
  championEndTime: number
  /**
   * 冠军竞猜时间
   */
  championStartTime: number
  /**
   * 冠军赛投票开关
   */
  championSwitch: boolean
  /**
   * 冠军竞猜队伍
   */
  championTeams: number[]
  [property: string]: any
}

export interface TeamInfo {
  id: number
  name: string
  betCount?: number
  [property: string]: any
}

export interface IActivityBetDetail {
  userId: number // 会员id
  username: string // 会员名
  totalBonus: number // 总奖金
  ticketCount: number // 票券数量
  guessList: IGuessItem[] // 已投票详情
  sixteenAmount: number // 十六强投票金额
  sixteenTeam: string[] // 十六强队伍
  eightAmount: number // 八强投票金额
  eightTeam: string[] // 八强队伍
  fourAmount: number // 四强投票金额
  fourTeam: string[] // 四强队伍
  myGuessList?: IGuessItem[] // 我的竞猜
}

export interface IGuessTeam {
  guessType: number
  teamId: number
}

export interface IGuessItem {
  teamId: number
  teamName: string
  betCount: number
}

export interface IGetMidAutumnActivityListReuqest {
  current: number
  size: number
  /**
   * 统计时间
   */
  statisticDate: number
  userId: number
}

export interface IGetSportsBettingConfigResponse {
  /**
   * 是否已参加活动
   */
  applied: boolean
  /**
   * 活动规则
   */
  awardList: AwardList[]
  /**
   * 是否已参加竞猜
   */
  bonused: boolean
  /**
   * 活动结束时间
   */
  endTime?: number
  /**
   * 倍率
   */
  multiple: number
  /**
   * 周期
   */
  ruleList: RuleList[]
  /**
   * 同设备校验
   */
  sameDeviceCheck: boolean
  /**
   * 同ip校验
   */
  sameIpCheck: boolean
  /**
   * 可参与竞猜
   */
  sportLeagueBonus: SportLeagueBonus
  /**
   * 活动开始时间
   */
  startTime?: number
  userLevels: number[]
  /**
   * 当前有效投注额
   */
  validBetAmount: number
}

export interface AwardList {
  /**
   * 奖励金额
   */
  awardAmount: number
  /**
   * 竞猜奖励额度
   */
  betAwardBonus: number
  /**
   * 区间最大投注额
   */
  maxLimitValidAmount: number
  /**
   * 区间最小投注额
   */
  minLimitValidAmount: number
}

export interface RuleList {
  /**
   * 周期结束时间
   */
  endTime: number
  /**
   * 周期id
   */
  ruleId: string
  /**
   * 周期开始时间
   */
  startTime: number
}

/**
 * 可参与竞猜
 */
export interface SportLeagueBonus {
  /**
   * 竞猜选中的队伍
   */
  bonusTeamId?: string
  /**
   * 竞猜结束时间
   */
  endTime: number
  /**
   * 比赛名
   */
  matchName: string
  /**
   * 活动周期id
   */
  ruleId: number
  /**
   * 竞猜开始时间
   */
  startTime: number
  /**
   * 队伍A
   */
  teamAId: string
  teamAName: string
  /**
   * 队伍B
   */
  teamBId: string
  teamBName: string
}

export interface IGetSportsBettingRuleResponse {
  /**
   * 活动类型
   */
  activityType?: number
  /**
   * 币种
   */
  coin?: string
  /**
   * 描述
   */
  description?: string
  /**
   * 编辑信息
   */
  operator?: {
    /**
     * 编辑者id
     */
    operatorId: number
    /**
     * 编辑人
     */
    operatorName: string
    /**
     * 更新时间
     */
    updateTime: number
  }
}

export interface IApplySportsBettingReq {
  /**
   * 参与竞猜必填，投注队伍id
   */
  bonusTeamId?: string
  /**
   * 活动周期id
   */
  ruleId: number
}

export interface IGetBalanceInterestConfigResponse {
  condition: number
  dailyInterestRate: number
  endTime: number
  interestLimit: number
  joined: boolean
  sameDeviceCheck: boolean
  sameIpCheck: boolean
  startTime: number
  userLevel: number[]
  statisticTime: string
}

export interface IGetBetCompensationResponse {
  /**
   * 参与活动游戏
   */
  availGames: number[]
  /**
   * 是否可参与活动
   */
  availJoin: boolean
  /**
   * 参与条件：投注金额
   */
  betAmount: number
  /**
   * 活动结束时间
   */
  endTime: number
  /**
   * 是否已参与活动
   */
  joined: boolean
  /**
   * 参与用户等级
   */
  levels: number[]
  /**
   * 流水倍数
   */
  multiple: number
  /**
   * 奖励上限
   */
  rewardLimit: number
  /**
   * 奖励比例
   */
  rewardPercent: number
  /**
   * 设备校验
   */
  sameDeviceCheck: boolean
  /**
   * ip校验
   */
  sameIpCheck: boolean
  /**
   * 活动开始时间
   */
  startTime: number
}

export interface IGetLuckyOrderRewardsRequest {
  current: number
  size: number
}

export interface IGetApplyLuckyOrderRequest {
  orderId: string
}

export interface IGetLuckyOrderRewardsResponse {
  current: number
  pages: number
  records: LuckyOrderReward[]
  size: number
  total: number
}

export interface LuckyOrderReward {
  /**
   * 投注额
   */
  betAmount?: number
  /**
   * 单号
   */
  orderId?: string
  /**
   * 奖励金额
   */
  rewardAmount?: number
  /**
   * 奖励倍数
   */
  rewardMultiple?: number
  /**
   * 状态
   */
  status?: number
}

export interface IGetLuckyOrderConfigResponse {
  /**
   * 条件：当日充值
   */
  dailyRecharge: number
  /**
   * 活动结束时间
   */
  endTime: number
  /**
   * 适用游戏
   */
  gameIds: number[]
  /**
   * 参与用户等级
   */
  levels: number[]
  /**
   * 流水倍数
   */
  multiple: number
  /**
   * 奖励信息
   */
  luckyRules: {
    /**
     * 幸运号码
     */
    luckyNum: string
    /**
     * 奖励
     */
    reward: string
  }[]
  /**
   * 同设备校验
   */
  sameDeviceCheck: boolean
  /**
   * 同ip校验
   */
  sameIpCheck: boolean
  /**
   * 活动开始时间
   */
  startTime: number
  /**
   * 修改人
   */
  updateId: number
  /**
   * 修改时间
   */
  updateTime: number
  /**
   * 修改人
   */
  updateUser: string
}
